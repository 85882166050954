import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BidService } from '../../../shared/services/MyServices/bid.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { BidList,BidWinnerList } from '../../../shared/models/bid';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';

@Component({
  selector: 'app-bidder',
  templateUrl: './bidder.component.html',
  styleUrls: ['./bidder.component.scss'],
  animations: egretAnimations
})
export class BidderComponent implements OnInit {

  constructor(private BidService: BidService, private router: Router, private CommonService: CommonService
    , private confirmService: AppConfirmService, private loader: AppLoaderService,private DataPassServic: DataPassService,
  ) { }
  AuctionDetailCode: any;
  public ServiceData: any[];
  public getItem: Subscription;
  public Winneritems: any[];
  public items: any[];

  FinalData = [];
  FinalWinnerData = [];
  SessionData: any;
  RoleName: any;
  BidSubTypeCode: any;
  temp = [];
  DispalyAction: boolean;
  ngOnInit() {


    this.SessionData = this.CommonService.getUserDetails();
    // console.log('this.SessionData');
    //console.log(this.SessionData);
    this.RoleName = this.SessionData.role_name;
    this.AuctionDetailCode = localStorage.getItem('BidAuctionDetailCode');

    if (this.AuctionDetailCode == null || this.AuctionDetailCode == '' || this.AuctionDetailCode == undefined) {
      this.router.navigateByUrl('pages/BidInventory');
    }
    else { this.getBidderList(this.AuctionDetailCode); }


    this.BidSubTypeCode = localStorage.getItem('BidType1')

    if (this.BidSubTypeCode == 'Closed' && this.RoleName != 'Admin') {

      this.DispalyAction = true;
    }
    else {

      this.DispalyAction = false;
    }



  }

  getBidderList(AucDetailCode) {
    this.items = [];
    this.Winneritems = [];
    this.FinalData = [];
    this.FinalWinnerData = [];
    // setTimeout(() => {
    //   this.loader.open();
    
    // });
    AucDetailCode = AucDetailCode;

    var json = { "auction_detail_code": AucDetailCode, "offset": 0 }
    // var json  = {"user_code":"CUST00001","offset":0,"auction_type": "running" }
    this.getItem = this.BidService.BindBidder(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
          // console.log('data');
          // console.log(data);
          this.ServiceData = data.data;
          for (let entry of this.ServiceData) {

            if (entry.is_winner ==  false) {
            this.FinalData.push(this.FormData(entry));
            }
            else{
             // this.FinalWinnerData.push(this.FormData(entry));
  this.getSettelmentList(entry.auction_detail_code) ;

            }

          }

          this.items = this.temp = this.FinalData;
        //  this.Winneritems = this.FinalWinnerData;
         // console.log('Not Win');
         // console.log(this.items);


         // console.log(this.Winneritems);
         // this.loader.close();

          // this.items = this.temp = data.data.find(x => x.is_winner === false);;
          // console.log( this.items);
          // console.log('data');
          // console.log(this.items);

        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      },
        error => {
          this.loader.close();
          Swal.fire(error.error.data.msg);
          console.log(error);
        });

  }

  getSettelmentList(auction_detail_code) {


    var Usercode
   // Usercode =parseInt(this.CommonService.getUserCode());
    Usercode=this.SessionData.user_code;
    var json = { "user_code": Usercode, "offset": 0 }
   
    this.getItem = this.BidService.BindBidWinner(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
         // console.log(data.data);
         var windata = data.data.find(x => x.auction_detail_code === auction_detail_code);
         this.FinalWinnerData.push(this.FormWinnerData(windata));
         this.Winneritems= this.FinalWinnerData;
         console.log(this.Winneritems);
        //  this.Winneritems = data.data.find(x => x.auction_detail_code === auction_detail_code);
          // console.log( this.items);

        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      });
  }

  FormData(Data): BidList {
    const objData: BidList = {} as BidList;
    objData.pin_code = Data.pin_code;
    objData.deposit_amount = Data.deposit_amount;
    objData.first_name = Data.first_name;
    objData.transaction_id = Data.transaction_id;
    objData.auction_detail_code = Data.auction_detail_code;
    objData.last_name = Data.last_name;
    objData.address2 = Data.address2;
    objData.mobile_no = Data.mobile_no;
    objData.address1 = Data.address1;
    objData.min_bid_amount = Data.min_bid_amount;
    objData.district = Data.district;
    objData.bid_amount = Data.bid_amount;
    objData.state = Data.state;
    objData.status = Data.status;
    objData.is_winner = Data.is_winner;
    objData.user_id = Data.user_id;
    objData.city = Data.city;
    objData.bid_created_at = Data.bid_created_at;
    objData.max_bid_amount = Data.max_bid_amount;
    return objData;
  }

  FormWinnerData(Data): BidWinnerList {
    const objData: BidWinnerList = {} as BidWinnerList;
    objData.auction_detail_code = Data.auction_detail_code;
    objData.first_name = Data.first_name;
    objData.mode = Data.mode;
    objData.dealer_distributor_code = Data.dealer_distributor_code;
    objData.dealer_user_code = Data.dealer_user_code;
    objData.dealer_status = Data.dealer_status;
    objData.dealer_remarks = Data.dealer_remarks;
    objData.status = Data.status;
    objData.dealer_position = Data.dealer_position;
    objData.dealer_remark_id = Data.dealer_remark_id;
    objData.mobile_no = Data.mobile_no;
    objData.created_at = Data.created_at;
    objData.buyer_remark_id = Data.buyer_remark_id;
    objData.is_settle = Data.is_settle;
    objData.payable_amount = Data.payable_amount;
    objData.tran_number = Data.tran_number;
    objData.buyer_user_code = Data.buyer_user_code;
    objData.inventory_title = Data.inventory_title;
    objData.email_id = Data.email_id;
    objData.deposit_amount = Data.deposit_amount;
    objData.dealer_username = Data.dealer_username;
    objData.bid_document_path = Data.bid_document_path;
    objData.dealer_remark_drp_value = Data.dealer_remark_drp_value;
    objData.inventory_code = Data.inventory_code;
    objData.buyer_status = Data.buyer_status;
    objData.last_name = Data.last_name;
    objData.buyer_remarks = Data.buyer_remarks;
    objData.bid_winner_id = Data.bid_winner_id;
    objData.buyer_remark_drp_value = Data.buyer_remark_drp_value;
    objData.bid_amount = Data.bid_amount;
    return objData;
  }



  openPopUp(data: any = {}, isNew?) {
    // console.log(data);
    this.DataPassServic.setBidSettelmentData(data);
    this.router.navigateByUrl('pages/SettelMentMaster');
  }

  back() {
    this.router.navigateByUrl('pages/BidInventory');
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }



  BidWinner(event) {
    //console.log(event);

    var json = { "user_code": this.SessionData.user_code, "auction_detail_code": event.auction_detail_code, "transaction_id": event.transaction_id }

    //var json =  { "auction_detail_code": event.transaction_id, "offset": 0 }





    this.confirmService.confirm({ message: `Are You Sure, Make this User As A Bid Winner???` })
      .subscribe(res => {
        if (res) {
          this.getItem = this.BidService.BidWinnner(json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }

              if (data.success = true) {
                Swal.fire('Bid Winner Selection Done!!', 'success');

              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }

            });
        }
      })


  }









}
