import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { SubCategoryDetails, CotrolData, InventoryMaster, FormArrayForFile, ControlDetail, ElementData, ControlBind } from '../../../shared/models/models';
import { CustomValidators } from 'ng2-validation';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import Swal from 'sweetalert2';
import { InventoryService } from '../../../shared/services/MyServices/inventory.service';
import { AuthorizeService } from '../../../shared/services/MyServices/authorize.service';
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { TataServiceService } from '../../../TataService/tata-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FileUploader } from 'ng2-file-upload';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../shared/directives/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import {VehicleData} from 'app/shared/models/models';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
  animations: egretAnimations,
})
export class TestPageComponent implements OnInit {
  TestForm: FormGroup;
 // public Data: any[];
  Data: VehicleData[];

  constructor(private fb: FormBuilder,
    private router: Router,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private commonService: CommonService,
    private AuthService: AuthorizeService,
    private imageCompress: NgxImageCompressService,
    private FileUpService: FileUploadService,
    private TataService: TataServiceService,
    private InvenService: InventoryService) {


  }

  ngOnInit() {
    this.buildItemForm(this.Data);
  }

  buildItemForm(item) {

    this.TestForm = this.fb.group({
      first_name: [ ''],
 last_name: [ ''],

})
  }

  onSearchChange(searchValue: string): void   
  {
    var json = {"vehicle_type":"TV","chassis_number":"426021MVZ753622","registration_number": "","dev_id": "89761","app_version": "1.0","app_name": "com.tatamotors.tataoksourcing"}
    this.TataService.VehicleSearch(json).subscribe(
      data => {

        if (data.vehicle_info) {
          console.log(data.vehicle_info);
        // data as  VehicleData;
         this.Data=data.vehicle_info;
    //     this.buildItemForm(this.Data);
    //     this.TestForm = this.fb.group({
    //       first_name: [ data.vehicle_info.registration_num],
    //  last_name: [ data.vehicle_info.registration_num],
    
    // })
    this.TestForm.get('first_name').setValue(data.vehicle_info.registration_num);
        }
        else {
         // Swal.fire('Oops...', 'incorrect username or password!', 'error')
        }
      }, (err) => {

      }
    );

  }


  // SearchVehicle() {
  //   var json = {"vehicle_type":"TV","chassis_number":"426021MVZ753622","registration_number": "","dev_id": "89761","app_version": "1.0","app_name": "com.tatamotors.tataoksourcing"}
  //   this.TataService.VehicleSearch(json).subscribe(
  //     data => {

  //       if (data.vehicle_info) {
  //         console.log(data.vehicle_info);
  //        data as  VehicleData;
        
  //       }
  //       else {
  //        // Swal.fire('Oops...', 'incorrect username or password!', 'error')
  //       }
  //     }, (err) => {

  //     }
  //   );
  // }

}
