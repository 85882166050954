import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment, } from '../../../../environments/environment';

import { Http, Headers, RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  private ViewAuctionbyCodeUrl = `${environment.TataapiUri}/api/v1/auction/view_auction_detail/`;
  private AddAuctionUrl = `${environment.TataapiUri}/api/v1/auction/addAuction/`;
  private AuctionListUrl = `${environment.TataapiUri}/api/v1/auction/listAuction/`;
  private UploadDocumentUrl = `${environment.TataapiUri}/api/v1/auction/upload/`;
  private AuctionApproveUrl = `${environment.TataapiUri}/api/v1/auction/approve_auction/`;
  private AuctionInventoryListUrl = `${environment.TataapiUri}/api/v1/auction/Auction_Inventory_List/`;
  private UpdateAuctionUrl = `${environment.TataapiUri}/api/v1/auction/edit_Auction_details/`;
  private InactiveAuctionInventoryUrl = `${environment.TataapiUri}/api/v1/inventory/active_inactive_inventory/`;
  private AuctionDataUrl = `${environment.TataapiUri}/api/v1/auction/auction_list/`;

  constructor(private http: HttpClient, private _Router: Router) { }

  AddAuction(data: any): Observable<any> {
    return this.http.post<any>(this.AddAuctionUrl, data).pipe(catchError(this.handlError));
  }
  AuctionList(data: any): Observable<any> {
    return this.http.post<any>(this.AuctionListUrl, data).pipe(catchError(this.handlError));
  }

  BindAuctionData(data: any): Observable<any> {
    return this.http.post<any>(this.AuctionDataUrl, data).pipe(catchError(this.handlError));
  }


  ApproveAction(data: any): Observable<any> {
    return this.http.post<any>(this.AuctionApproveUrl, data).pipe(catchError(this.handlError));
  }

  AuctionInventoryList(data: any): Observable<any> {
    return this.http.post<any>(this.AuctionInventoryListUrl, data).pipe(catchError(this.handlError));
  }

  ViewAuctionByCode(data: any): Observable<any> {
    return this.http.post<any>(this.ViewAuctionbyCodeUrl, data).pipe(catchError(this.handlError));
  }


  updateAuction(data: any): Observable<any> {
    return this.http.post<any>(this.UpdateAuctionUrl, data).pipe(catchError(this.handlError));
  }
  InactiveActionInventory(data: any): Observable<any> {
    return this.http.post<any>(this.InactiveAuctionInventoryUrl, data).pipe(catchError(this.handlError));
  }

  createAuthHeader(headers: Headers) {
    //headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  }
  private httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  }

  private headerDict = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
  }

  private requestOptionss = {
    headers: new Headers(this.headerDict),
  };


  post(data: FormData) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(this.UploadDocumentUrl, data, { headers: headers });
    //    return this.http.post(this.UploadDocumentUrl , data, this.httpOptions).pipe(catchError(this.handlError));
  }

  uploadImageDocument(data: FormData) {
    return this.http.post<any>(this.UploadDocumentUrl, data).pipe(catchError(this.handlError))
  }
  //uploadData(data: any): Observable<any> {
  uploadData(data: FormData) {

    return this.http.post<any>(this.UploadDocumentUrl, JSON.stringify(data)).pipe(catchError(this.handlError))
    //return this.http.post(this.UploadDocumentUrl, data);
    //     const headers = new Headers();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // headers.append( 'No-Auth', 'True');
  }


  // return this.http.post(
  //     this.UploadDocumentUrl + '/token'
  //    , data.toString()
  //    , { headers: headers }
  //   )
  //   .pipe(map(res => res.json()))
  //   .pipe(map(res => {
  //     localStorage.setItem('auth_token', res.auth_token);
  //     return true;
  //   }))
  //   .pipe(catchError((error: any) => {
  //     return Observable.throw(error);
  //   }));
  //   }



  // uploadData(data: any): Observable<any> {
  //   const headers= new HttpHeaders(
  //     {
  //       'Content-Type': `application/x-www-form-urlencoded`,
  //     }
  //   )

  //   return this.http.post(this.UploadDocumentUrl,data,
  //     {
  //       headers: headers
  //     });
  //   // return this.http.post(this.UploadDocumentUrl, data, {
  //   //          headers:{"Content-Type": "application/x-www-form-urlencoded"},
  //   //          observe: 'response' }).pipe(catchError(this.handlError));
  //   //return this.http.post<any>(this.UploadDocumentUrl,data).pipe(catchError(this.handlError));
  // }

  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }
}