import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment, } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  //private LoginUrl = `${environment.apiUri}/api/tataoksourcing/user/login/`;
  private LoginUrl = `${environment.TataapiUri}/api/login/`;
  private AdminLoginUrl = `${environment.TataapiUri}/api/v1/accounts/login/`;
  private TATALoginUrl = `${environment.TataapiUri}/api/login/`;
  private logOutUrl = `${environment.TataapiUri}/api/v1/accounts/catalogue/logout/`;

  constructor(private http: HttpClient, private _Router: Router,) { }


  TataloginAuth(data: any): Observable<any> {
    return this.http.post<any>(this.TATALoginUrl, data).pipe(catchError(this.handlError));
  }

  loginAuth(data: any): Observable<any> {
    return this.http.post<any>(this.AdminLoginUrl, data).pipe(catchError(this.handlError));
  }

  logOut(): Observable<any> {
    return this.http.post<any>(this.logOutUrl,'').pipe(catchError(this.handlError));
  }

  ExpireDateTime: any;
  loggedIn() {
    this.ExpireDateTime = JSON.parse(localStorage.getItem('timer'));
    let date1 = new Date();
    let date2 = new Date(this.ExpireDateTime);
    if (date2 >= date1) {
      if (localStorage.getItem('token') && localStorage.getItem('token') != "null") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      localStorage.clear();
      return false;
    }


  }

  public getToken(): string {
    if (this.loggedIn()) {
      return localStorage.getItem('token');
    }
    else {
    }
  }
  public getTataToken(): string {

    return localStorage.getItem('TataToken');
  }


  loggedOut() {
    localStorage.removeItem('token')
    localStorage.clear();
    sessionStorage.clear();
    this._Router.navigate(['/sessions/signin4']);
  }
  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }


}
