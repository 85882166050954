import { Component, OnInit } from '@angular/core';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatInputModule } from '@angular/material';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import Swal from 'sweetalert2';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { BidService } from '../../../shared/services/MyServices/bid.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-settelmentmaster',
  templateUrl: './settelmentmaster.component.html',
  styleUrls: ['./settelmentmaster.component.scss']
})
export class SettelmentmasterComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({ url: 'https://evening-anchorage-315.herokuapp.com/api/' });
  constructor(private DataPassServic: DataPassService, private confirmService: AppConfirmService,
    private FileUpService: FileUploadService,
    private fb: FormBuilder, private commonService: CommonService, private BidService: BidService,
    private router: Router) { }

  public DataPassService: any;
  public itemForm: FormGroup;
  public Id: string;
  public Status: string;
  isAccept: any;
  ReasonData: any[];
  submitted = false;
  DocumentFile: any;
  public hasBaseDropZoneOver: boolean = false;
  ngOnInit() {


    this.isAccept = "none";



    this.DataPassService = this.DataPassServic.getsetBidSettelmentData();
    console.log('this.datas');
    console.log(this.DataPassService);

    console.log(this.DataPassServic);



    // this.buildItemForm(this.DataPassService)


    if (this.DataPassService.bid_winner_id == undefined) {

      this.router.navigateByUrl('pages/BidSettelMent');
    }
    else {

      this.buildItemForm(this.DataPassService)
    }




  }

  BindReason() {
    var Json = { "remark_type": "BID_WINNER" }
    this.commonService.BindReasonType(Json).subscribe(
      data => {
        if (data.success == true) {
          this.ReasonData = data.data;
          console.log(this.ReasonData);

        }


      }, (err) => {
        Swal.fire('Oops...', err.msg, 'error')
      }
    );
  }

  onSelectreason(Value) {

    this.itemForm.get('tran_number').setValue('');
    this.itemForm.get('mode').setValue('');
    this.itemForm.get('payable_amount').setValue('');
    this.itemForm.get('remarktype_id').setValue('');
    this.itemForm.get('remark').setValue('');




    // alert(Value);

    if (Value == 'Accept') {


      this.ReasonData = [];
      var data = { "remarktype_id": 1, "remark": "Accept", "remark_type": "BID_WINNER", "party_type_id": 1, "created_at": "2020-02-18T10:31:19.955701Z", "is_active": true }
      this.ReasonData.push(data);
      this.isAccept = "";




    }
    else {

      this.itemForm.get('tran_number').setValidators(null);
      this.itemForm.get('tran_number').setErrors(null);


      this.itemForm.get('payable_amount').setValidators(null);
      this.itemForm.get('payable_amount').setErrors(null);


      this.itemForm.get('mode').setValidators(null);
      this.itemForm.get('mode').setErrors(null);








      this.isAccept = "none";
      this.BindReason();

    }


  }


 
  get f() { return this.itemForm.controls; }


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }


  buildItemForm(item) {
    // this.Id = item.deposit_details_id;
    // this.Status = item.status;

if(item.bid_document_path == '' || item.bid_document_path == undefined || item.bid_document_path == null)
{

}


    if (item.dealer_status == 'Accept') {


      this.ReasonData = [];
      var data = { "remarktype_id": 1, "remark": "Accept", "remark_type": "BID_WINNER", "party_type_id": 1, "created_at": "2020-02-18T10:31:19.955701Z", "is_active": true }
      this.ReasonData.push(data);
      this.isAccept = "";




    }
    else if (item.dealer_status == 'Reject') {

      this.itemForm.get('tran_number').setValidators(null);
      this.itemForm.get('tran_number').setErrors(null);


      this.itemForm.get('payable_amount').setValidators(null);
      this.itemForm.get('payable_amount').setErrors(null);


      this.itemForm.get('mode').setValidators(null);
      this.itemForm.get('mode').setErrors(null);








      this.isAccept = "none";
      this.BindReason();

    }
    else
    {
      this.isAccept = "none";

    }


    this.itemForm = this.fb.group({
      inventory_title: [item.inventory_title || ''],
      auction_title: ['' || ''],
      first_name: [item.first_name || ''],
      last_name: [item.last_name || ''],
      mobile_no: [item.mobile_no || ''],
      // AuctionCode: [item.auction_code || ''],
      //AuctionTitle: [item.auction.auction_title || ''],
      bid_winner_id: [parseInt(item.bid_winner_id) || ''],
      dealer_bid_status: [item.dealer_status || ''],
      mode: [item.mode || ''],
      tran_number: [item.tran_number , Validators.required],
      remark_id: [],
      remark: [item.dealer_remarks || ''],
      deposit_amount: [item.deposit_amount || ''],

      payable_amount: [item.payable_amount || ''],
      remarktype_id: ['' || ''],
      auction_detail_code: [item.auction_detail_code || ''],
      agreed: ['' || ''],
    })



  }

  SelectDocumentFiles(event) {
    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
      const reader = new FileReader();
      const file = event.target.files[0];
      this.DocumentFile = file;
      console.log('this.DocumentFile');
      console.log(this.DocumentFile);
      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
          // this.DocumentFile = target.result;
        }
      }
      else {
        Swal.fire('Oops...', 'Upload only 5 MB size files!', 'error')
      }

    }




  }
UploadImgage(bid_winner_id)
{

  console.log('this.uploader');
  //console.log(this.uploader.fil);
  var Check = false;

  const formData = new FormData();
  formData.append('bid_winner_id',  bid_winner_id);
  //formData.append('auction_code','AUCT00006');
  // formData.append('tran_code', '15656dewsdedewde3e2dd88eeu');
  // formData.append('mode', 'NEFT');


  // formData.append('deposit_amount', '100000');

  // formData.append('deposit_type', 'Deposited');

  formData.append('bid_settlement_document',  this.DocumentFile);





  Check = true
  if (Check) {
    this.FileUpService.uploadImage(formData).subscribe(data => {
      console.log('Upload Image document ' + data);
    });
  }
  else {
  }




}

  btnsubmitForm() {


    if (this.itemForm.invalid) {
      return;
    }

    console.log(this.itemForm.value.agreed);

    this.submitted = true;
    console.log('this.itemForm.value');
    console.log(this.itemForm.value);


    this.confirmService.confirm({ message: `Are You Sure to Submit  this Data?` })
      .subscribe(res => {
        if (res) {
          // let json=this.formData(this.itemForm.value);
          //let json = '{"user_code":"' + this.itemForm.value.user_code + '", "status":"Active"}';
          let json = '{"bid_winner_id":"' + this.itemForm.value.bid_winner_id + '","bid_user_status":"' + this.itemForm.value.dealer_bid_status + '","mode":"' + this.itemForm.value.mode + '","tran_number":"' + this.itemForm.value.tran_number + '", "remark_id":"' + Number(this.itemForm.value.remarktype_id) + '","remark":"' + this.itemForm.value.remark + '","auction_detail_code":"' + this.itemForm.value.auction_detail_code + '" ,"payable_amount":"' + this.itemForm.value.payable_amount + '" }'
          this.BidService.ApproveBidSettelment(json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                this.UploadImgage(this.itemForm.value.bid_winner_id);
                this.router.navigateByUrl('pages/BidSettelMent');
                Swal.fire('Data Submit Successfully!');
              }
              else {
                Swal.fire(data.data.msg)
              }
            },
            error => {
              Swal.fire(error.error.data.msg);
            });
        }
      })

  }


  Calcelclick() {

    this.router.navigateByUrl('pages/DepositList');

  }


  formData(Datas): BidSettlement {
    const ArrayData: BidSettlement = {} as BidSettlement;
    ArrayData.bid_winner_id = parseInt(Datas.bid_winner_id);
    ArrayData.remark_id = Datas.remarktype_id;
    ArrayData.dealer_bid_status = Datas.bid_user_status;
    ArrayData.remark = Datas.remark;


    ArrayData.mode = Datas.remark_id.mode;
    ArrayData.tran_number = Datas.tran_number;
    ArrayData.auction_detail_code = Datas.auction_detail_code;


    ArrayData.payable_amount = Datas.payable_amount




    return ArrayData;
  }




}


export class BidSettlement {
  bid_winner_id: number;
  dealer_bid_status: string;
  remark_id: number;
  remark: string;


  mode: string;

  tran_number: string;

  auction_detail_code: string;
  payable_amount: string;

}




