import { Component, OnInit } from '@angular/core';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApproveData } from 'app/shared/models/models';

import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { Subscription } from 'rxjs';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { TransactionService } from 'app/shared/services/MyServices/transaction.service';
import Swal from 'sweetalert2';
import { egretAnimations } from "../../../shared/animations/egret-animations";

@Component({
  selector: 'app-deposit-master',
  templateUrl: './deposit-master.component.html',
  styleUrls: ['./deposit-master.component.scss'],
  animations: egretAnimations
})
export class DepositMasterComponent implements OnInit {
ViewDocument:any;
  constructor(private DataPassServic: DataPassService,
    private confirmService: AppConfirmService,
    private TransService: TransactionService,
    private fb: FormBuilder,
    private router: Router) { }

  public DataPassService: any;
  public itemForm: FormGroup;
  public Id: string;
  public Status: string;
  ngOnInit() {


    this.DataPassService = this.DataPassServic.getDepositeData();
    console.log('this.datas');
    console.log(this.DataPassService);

    console.log(this.DataPassServic);

    if (this.DataPassService.deposit_details_id == undefined) {

      this.router.navigateByUrl('pages/DepositList');
    }
    else {

      this.buildItemForm(this.DataPassService)
    }




  }

  onSearchChange(searchValue: string): void   
  {
    this.itemForm.controls.first_name.setValue('foo')
  }


  buildItemForm(item) {
    this.Id = item.deposit_details_id;
    this.Status = item.status;
this.ViewDocument=item.deposit_document_path;
 

    this.itemForm = this.fb.group({
      username: [item.user_name || ''],
      first_name: [item.user.first_name || ''],
      last_name: [item.user.last_name || ''],
      email_id: [item.user.email_id || ''],
      phone_number: [item.user.mobile_no || ''],
      AuctionCode: [item.auction_code || ''],
     // AuctionTitle: [item.auction.auction_title || ''],
      AuctionStatus: [item.status || ''],
      PaymentMode: [item.mode || ''],
      PaymentModeRefNo: [item.tran_code || ''],
      DepositeAmount: [item.deposit_amount || ''],
      DepositeDetailId: [item.deposit_details_id || ''],
      Status: [item.status || '']

    })
  }

  Calcelclick() {
  
    this.router.navigateByUrl('pages/DepositList');

  }

  ApproveDeposit(data: any = {}, isNew?) {

    this.confirmService.confirm({ message: `Are You Sure to Approve This Deposite?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          objData.id = data.toString();
          // alert(Id)
          this.TransService.ApproveDepoist(objData)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                this.router.navigateByUrl('pages/DepositList');
                Swal.fire('Deposit Approved Successfully!');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });
  }


}
