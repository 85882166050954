import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-view-enquiry-popup',
  templateUrl: './view-enquiry-popup.component.html',
  styleUrls: ['./view-enquiry-popup.component.scss']
})
export class ViewEnquiryPopupComponent implements OnInit {
GetVCData:any;
  @Input() Data: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
  private loader: AppLoaderService,
  private datepipe: DatePipe,
  private dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.data.payload)
    if(this.data.payload==undefined ||
       this.data.payload==null ||
       this.data.payload==''||
       this.data.payload==" "||
       this.data.payload== NaN )
    {
      Swal.fire('Something went wrong')
    }
    else{
      this.GetVCData=this.data.payload
      console.log('saq',this.GetVCData)
    }
  }

}
