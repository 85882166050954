import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { AuctionInventoryList, auction_details, AuctionMaster, AuctionCartData, ControlDetail, InventoryList, ApproveData } from '../../../shared/models/models';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuctionService } from '../../../shared/services/MyServices/auction.service';
import { InventoryService } from '../../../shared/services/MyServices/inventory.service';
import { AuthorizeService } from '../../../shared/services/MyServices/authorize.service';
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../shared/directives/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImagePopupComponent } from '../image-popup/image-popup.component';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service'
import { TataServiceService } from '../../../TataService/tata-service.service';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'app-auction-master',
  templateUrl: './auction-master.component.html',
  styleUrls: ['./auction-master.component.scss'],
  animations: egretAnimations,
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class AuctionMasterComponent implements OnInit {
  AttachementFile: string;
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  statebind: any;
  public ServiceData: any[];
  FinalData = [];
  InventoryImages = [];
  InventoryImagesService = [];

  error: any = { isError: false, errorMessage: '' };
  editing = {};
  rows = [];
  todayDate = new Date();
  RefInput: ElementRef;
  disableread: boolean[] = [];
  imageSrc: string;
  public uploader: FileUploader = new FileUploader({ url: '' });
  public hasBaseDropZoneOver: boolean = false;
  console = console;
  buttonDisabled: boolean;
  ListData: InventoryList[];
  temp = [];
  public items: any[];
  public Cartitems: any[];
  Binditems = [];
  SaveData = [];
  imgurls = [];
  CompressImage: any;
  DocumentFile: any;
  btnInventory: any;

  IsDocumentChanged: any;
  IsImageChanged: any;
  //public Cartitems: any[];

  public getItemSub: Subscription;
  public GetData: any;
  public datas: any;

  firstFormGroup: FormGroup;
  firstFormGrouptest: FormGroup;
  secondFormGroup: FormGroup;
  selected = 'option2';

  StateArray: any[];
  DistictArray: any[];
  CityArray: any[];
  TalukaArray: any[];
  PincodeArray: any[];

  stateCode: string;
  stateName: string;
  districtName: string;
  cityName: string;
  talukaName: string;
  postalCode: string;
  auctionId: any;
  AAUctionCode: any;
  btnSave: boolean;
  btnUpdate: boolean;
  btnApprove:boolean
  Inactive: boolean;
  RemoveLink: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private commonService: CommonService,
    private AuthService: AuthorizeService,
    private InvenService: InventoryService,
    private datepipe: DatePipe,
    private imageCompress: NgxImageCompressService,
    private FileUpService: FileUploadService,
    private DataPass: DataPassService,
    private TataService: TataServiceService,
    private AuctionService: AuctionService,
    ) {

    // this.todayDate.setDate(this.todayDate.getDate())
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  minDate: Date;
  ngOnInit() {
    this.minDate = new Date();
    this.IsImageChanged = "No";
    this.IsDocumentChanged = "No";
    this.login_();
    this.GetData = this.DataPass.getOption();
    this.AAUctionCode = this.GetData.auction_code;
    this.auctionId = this.GetData.auction_id
    this.btnInventory = "Add";
    var AunCode = localStorage.getItem('AuctionCode');
    if (this.auctionId == null || this.auctionId == '' || this.auctionId == undefined) {
      this.buildItemForm('');
      this.btnSave = true;
      this.btnUpdate = false;
    }
    else {
      this.btnSave = false;
      this.btnUpdate = true;
      this.GetData = this.DataPass.getOption();
      this.buildItemForm(this.GetData)
    }
    localStorage.removeItem('AuctionCode');
    this.DataPass.resetOption();
    this.buttonDisabled = false;
    this.secondFormGroup = this.fb.group({
      NewInventoryData: this.fb.array([]),
    });
    // // this.secondFormGroup = this.fb.group({
    // //   // inventory_code: [''],
    // //   // deposit_amount: [''],
    // //   min_bid_amount: [''],
    // //   max_bid_amount: ['']
    // //   // secondCtrl: ['', Validators.required]
    // // });
    this.getItems()
    // this.getAllInventoryList();

  }


  login_() {
    var json = { "username": "BRAI1003550", "password": "SBRAI1003550", "dev_id": "897jhjh6", "app_version": "1.0", "app_name": "com.tatamotors.tataoksourcing" }
    this.AuthService.TataloginAuth(json).subscribe(
      data => {

        if (data.data.length > 0) {
          this.setTataSession(data)
        }
        else {
          // Swal.fire('Oops...', 'incorrect username or password!', 'error')
        }
      }, (err) => {
        console.log(err);
        Swal.fire(err.error.msg);
      }
    );
  }
  setTataSession(data) {
    localStorage.setItem('TataToken', data.token.access_token);
  }



  serviceData() {

    let json = '{"auction_code":"' + 'AUCT00008' + '"}';
    this.AuctionService.ViewAuctionByCode(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success) {

          this.datas = data.data[0];
          this.buildItemForm(this.GetData)
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      });
  }


  get getmultipleControl() {
    return <FormArray>this.secondFormGroup.get('NewInventoryData');
  }

  bindValueArray(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date) {
    return this.fb.group(
      {
        cover_image: [coverImage],
        inventory_id: [inventory_id],
        inventory_code: [inventory_code],
        inventory_title: [InventoryTitle],
        inv_type: [inv_type],
        category_type: [category_type],
        subcategory_type: [subcategory_type],
        start_at: [''],
        start_time: [''],
        end_at: [''],
        min_bid_amount: [''],
        max_bid_amount: [''],
        //isEditable: [true]
      }
    )
  }

  buildItemForm(item) {
    if (item == '') {
      this.todayDate.setDate(this.todayDate.getDate());
      this.RemoveLink = true;
      this.firstFormGroup = this.fb.group({
        auction_title: ['', Validators.required],
        bid_type: ['', Validators.required],
        auction_price_type: ['', Validators.required],
        auction_type: ['', Validators.required],
        DepositAmount: ['50000'],
        start_date: ['', Validators.required],
        //  start_time: [item.start_time || '', Validators.required],
        sHH: ['', Validators.required],
        sMM: ['', Validators.required],
        // sAM: [item.sAM || '', Validators.required],
        end_date: [''],
        //end_time: [item.end_time || '', Validators.required],
        eHH: [''],
        eMM: [''],
        // eAM: [item.eAM || '', Validators.required],
        state: [''],
        district: [''],
        city: [''],
        taluka: [''],
        postal_code: [''],
        contact_no: ['', Validators.required],
        auction_remark: ['', Validators.required],
        location_address: ['', Validators.required],
        auction_details: this.fb.array([this.createItem()])
      });
    }
    else {
      var ServiceImage = "";
      var sHH = "";
      var sMM = "";
      // var sAM = "";
      var eHH = "";
      var eMM = "";
      // var eAM = "";
      
      
    
      var TempstartTime = item.start_time
      var splitsstarytime = TempstartTime.split(":")

      if(item.auction_type != 'CONTINUOUS'){
      var TempEndTime = item.end_time
      var splitsEndtime = TempEndTime.split(":")
      }
      if (item.auction_status == "APPROVED" ) {
      this.btnApprove = false;
      }
      else
      {
        this.btnApprove = true;
      }

      this.CompressImage = item.cover_img_path;
      this.imageSrc = item.cover_img_path;
      this.DocumentFile = item.document_path;
      this.RemoveLink = true;
      if (item.auction_status == "APPROVED" && item.auction_type != 'CONTINUOUS') {
        this.btnUpdate = false;
        this.Inactive = true;
        this.RemoveLink = false;
      }



      this.todayDate.setDate(item.start_date);

      this.firstFormGroup = this.fb.group({
        auction_status: [item.auction_status || '', Validators.required],
        auction_title: [item.auction_title || '', Validators.required],
        bid_type: [item.bid_type || '', Validators.required],
        auction_price_type: [item.auction_price_type || '', Validators.required],
        auction_type: [item.auction_type || '', Validators.required],
        DepositAmount: [item.deposit_amount],
        start_date: [item.start_date || '', Validators.required],
        //  start_time: [item.start_time || '', Validators.required],
        sHH: [splitsstarytime[0] || '', Validators.required],
        sMM: [splitsstarytime[1] || '', Validators.required],
        // sAM: [item.sAM || '', Validators.required],
        // // end_date: [item.end_date || ''],
        // // eHH: [splitsEndtime[0] || ''],
        // // eMM: [splitsEndtime[1] || ''],
        end_date: [ ''],
        eHH: [''],
        eMM: [''],
        //end_time: [item.end_time || '', Validators.required],
       
        // eAM: [item.eAM || '', Validators.required],
        state: [item.state || ''],
        district: [item.district || ''],
        city: [item.city || ''],
        taluka: [item.taluka || ''],
        postal_code: [item.postal_code || ''],
        contact_no: [item.contact_no || '', Validators.required],
        auction_remark: [item.auction_remark || '', Validators.required],
        location_address: [item.location_address || '', Validators.required],
        auction_details: this.fb.array([this.createItem()])
      });

      this.todayDate.setDate(this.todayDate.getDate())
      // alert(this.todayDate);


      for (let entry of item.auction_detail) {
        // debugger;

        // console.log(entry.cat_id);

        this.InventoryImagesService = entry.inventory_documents.images;
        if (this.InventoryImagesService.length > 0) {

          var images = this.InventoryImagesService.find(x => x.is_cover === true);
          // debugger;
          if (images == '' || images == undefined || images == null) {
            var images = this.InventoryImagesService[0];
            ServiceImage = images.path;
          }
          else {
            ServiceImage = images.path;
          }
        }

        // inventory_id,    inventory_code,    inventory_title,    cover_image,    inv_type,    
        // category_type,    subcategory_type,    inventory_status,    approved_status,
        // min_bid_amount,    max_bid_amount

        this.Binditems.push(this.formDataEdit(
          entry.inventory_id, entry.inventory_code, entry.inventory_title, 
          ServiceImage, "", entry.cat_name, entry.sub_cat_name, "", "", entry.start_at,entry.end_at,entry.min_bid_amount, entry.max_bid_amount, entry.auction_detail_code,
          entry.total_bids));
      }
    }



    this.stateName = item.state;
    this.districtName = item.district;
    this.cityName = item.city;
    this.talukaName = item.taluka;
    this.postalCode = item.postal_code;
    this.TataService.getBindState('').then((res => {
      if (res.length > 0) {
        this.StateArray = res;
        // console.log(this.stateName);
        if (this.stateName != undefined) {
          var selectData = this.StateArray.filter(data => data.name === this.stateName);
          this.firstFormGroup.controls['state'].setValue(selectData[0]);
          this.onSelectState(selectData[0]);
        }
        // this.firstFormGroup.setValue({
        //   state: selectData,
        // });
        this.DistictArray = [];
        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];

      }

    }));

  }


  createItem(): FormGroup {
    return this.fb.group({
      inventory_code: [''],
      deposit_amount: [''],
      min_bid_amount: [''],
      max_bid_amount: [''],
    });
  }


  onSelectState(value) {
    this.stateCode = value.code;
    this.stateName = value.name;
    this.firstFormGroup.value.state = value.code;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"}}';
    this.TataService.BindDistrict(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.DistictArray = res;

        if (this.districtName != undefined) {
          // var selectDistrictData = this.DistictArray.filter(data => data.name === this.districtName);
          this.onSelectDistrict(this.districtName);
          // this.firstFormGroup.controls['city'].setValue(this.districtName);
          //  this.onSelectState(selectDistrictData[0]);
        }

        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }



  onSelectDistrict(value) {
    this.districtName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '"}';
    this.TataService.BindCity(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.CityArray = res;

        if (this.cityName != undefined) {
          this.onSelectCity(this.cityName);
        }

        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  onSelectCity(value) {
    this.cityName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '"}';
    this.TataService.BindTaluka(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.TalukaArray = res;
        if (this.talukaName != undefined) {
          this.onSelectTaluka(this.talukaName);
        }
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }

  onSelectTaluka(value) {
    this.talukaName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '","taluka":"' + this.talukaName + '"}';
    this.TataService.BindPincode(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.pincodes.length > 0) {
        this.PincodeArray = res.pincodes;
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  ngOnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe()
    }
  }





  getItems() {
    this.FinalData = [];

    this.items = null;

    this.secondFormGroup.reset();

    const InventoryListrData: InventoryList = {} as InventoryList;
    InventoryListrData.user_id = 1;
    InventoryListrData.offset = 0;
    this.getItemSub = this.AuctionService.AuctionInventoryList(InventoryListrData)
      .subscribe(data => {

        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
          //this.console.log(data.data);
          this.ServiceData = data.data;
          let inventory_id: number;
          let inventory_code, InventoryTitle, coverImage: string;
          let inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date, DealerCode: string;
          //console.log('this.ServiceData');
          //console.log(this.ServiceData);
          for (let entry of this.ServiceData) {
            inventory_id = entry.inventory_id;
            inventory_code = entry.inventory_code;
            inv_type = entry.cat_name;
            category_type = entry.sub_cat_name;
            subcategory_type = entry.sub_cat_detail_name;
            InventoryTitle = entry.inventory_title;
            inventory_status = entry.inventory_status;
            approved_status = entry.approved_status;
            Inv_date = new Date(entry.created_at);
            DealerCode = entry.dealer_code;
            this.InventoryImages = entry.inventory_documents.images;
            if (this.InventoryImages.length > 0) {
              var images = this.InventoryImages.find(x => x.is_cover === true);
              // debugger;
              if (images == '' || images == undefined || images == null) {
                var images = this.InventoryImages[0];
                coverImage = images.path;
              }
              else {
                coverImage = images.path;
              }
            }
            else {
            }
            //  this.FinalData.push(this.formInvListData(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date));
            this.getmultipleControl.push(this.bindValueArray(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date));
          }

          //         this.items = this.temp = data.data as InventoryList[];
          this.items = this.temp = this.FinalData;

          let yFilter = this.Binditems.map(itemY => { return itemY.inventory_id; });

          // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
          let filteredX = this.items.filter(itemX => !yFilter.includes(itemX.inventory_id));

          // for (let index in this.items) {
          // //  console.log('Index');
          //   //console.log(index);
          //   this.editing[index] = false;
          // };

          for (let index in this.secondFormGroup.get('NewInventoryData').value) {
            this.editing[index] = false;
          };
          this.items = filteredX;
          for (let i = 0; i < this.items.length; i++) {
            this.disableread[i] = false;
          }
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      });

    // this.getItemSub = this.crudService.getItems()
    //   .subscribe(data => {
    //     this.items = data;
    //   })
  }
  openPopUp(data: any = {}, isNew?) {
    this.items = data;
    this.router.navigateByUrl('pages/Auction');
  }
  deleteItem(row, rowIndex) {
    this.confirmService.confirm({ message: `Delete ${row.inventory_code}?` })
      .subscribe(res => {
        if (res) {
          this.editing[rowIndex] = true;
          this.loader.open();
          this.removeItem(row).subscribe(data => {
              this.Cartitems = data;
              this.loader.close();
              //this.snack.open('Inventory deleted!', 'OK', { duration: 4000 })
              Swal.fire('Remove', 'Inventory Removed!')
            })
        }
      })
  }

  loadServiceData() {
    //alert('Call');
    this.getItems()

  }


  removeItem(row) {
    // this.Cartitems = this.Binditems
    let i = this.Cartitems.indexOf(row);
    this.Cartitems.splice(i, 1);
    //  let j = this.Binditems.indexOf(row);
    //   this.Binditems.splice(j, 1);
    return of(this.Cartitems.slice()).pipe(delay(1000));
  }
  AddInventory(selectedItem: any, rowIndex) {
    //  console.log(this.secondFormGroup.value);
    // console.log(selectedItem);
    var maxbidamout = selectedItem.max_bid_amount;
    var minbidamout = selectedItem.min_bid_amount;
    if (maxbidamout != "" && minbidamout != "" && selectedItem.start_at && selectedItem.start_time) {

      var existItem = this.Binditems.find(x => x.inventory_code == selectedItem.inventory_code);
      if (existItem) {
        const control = this.secondFormGroup.get('NewInventoryData') as FormArray;
        control.removeAt(rowIndex);

        this.editing[rowIndex] = false;
        Swal.fire('Inventory', 'Inventory  Already Added!')
      }
      else {
        if (parseInt(maxbidamout) < parseInt(minbidamout)) {
          Swal.fire('', 'Min Amount should be less than Max Amount!', 'error');
        }
        else {
          this.editing[rowIndex] = true;
          //this.Binditems.push(this.formData(selectedItem, maxbidamout, minbidamout));
          this.Binditems.push(this.formData(selectedItem));
          maxbidamout = "";
          minbidamout = "";
          // this.secondFormGroup.value.max_bid_amount = "";
          //this.secondFormGroup.value.min_bid_amount = "";
          // this.snack.open('Inventory Added!', 'OK', { duration: 4000 })
          Swal.fire('Added To Auction', 'Inventory Added Sucessfully!')
        }
      }
    }
    else {
      Swal.fire('', 'Enter all input');
    }

  }


  // formData(Datas, maxbidamout, minbidamout): AuctionCartData {
  //   const ArrayData: AuctionCartData = {} as AuctionCartData;
  //   ArrayData.inventory_id = Datas.inventory_id;
  //   ArrayData.inventory_code = Datas.inventory_code;
  //   ArrayData.cat_name = Datas.cat_name;
  //   ArrayData.sub_cat_name = Datas.sub_cat_name;
  //   ArrayData.min_bid_amount = minbidamout;
  //   ArrayData.max_bid_amount = maxbidamout;
  //   return ArrayData;
  // }

  formDataEdit(inventory_id, inventory_code, inventory_title, cover_image, inv_type, category_type, subcategory_type, inventory_status, approved_status,
    start_at,end_at,min_bid_amount, max_bid_amount, auction_detail_code, total_bids): AuctionCartData {
    const ArrayData: AuctionCartData = {} as AuctionCartData;
    ArrayData.inventory_id = inventory_id;
    ArrayData.inventory_code = inventory_code;
    ArrayData.inventory_title = inventory_title;
    ArrayData.cover_image = cover_image;
   ArrayData.category_type = category_type;
    ArrayData.subcategory_type = subcategory_type;
    ArrayData.start_at = start_at;
    ArrayData.end_at = end_at;
    ArrayData.min_bid_amount = min_bid_amount;
    ArrayData.max_bid_amount = max_bid_amount;
    ArrayData.auction_detail_code = auction_detail_code;
    ArrayData.total_bids = total_bids;
    return ArrayData;
  }


  inventorystartat: string;
  inventoryendat: string;
  formData(Datas): AuctionCartData {
    debugger;
    const ArrayData: AuctionCartData = {} as AuctionCartData;
    ArrayData.inventory_id = Datas.inventory_id;
    ArrayData.auction_detail_code = "";
    ArrayData.inventory_code = Datas.inventory_code;
    ArrayData.inventory_title = Datas.inventory_title;
    ArrayData.cover_image = Datas.cover_image;
    ArrayData.category_type = Datas.category_type;
    ArrayData.subcategory_type = Datas.subcategory_type;
    this.inventorystartat = Datas.start_at + ' ' + Datas.start_time;
   ArrayData.start_at = this.datepipe.transform(this.inventorystartat, 'yyyy-MM-dd HH:mm:ss.SSS');
  // ArrayData.start_at = this.inventorystartat  +'.'+0;;
    var startdate = new Date(this.inventorystartat);
    startdate.setHours(startdate.getHours() + 72);
    var enddate = this.datepipe.transform(startdate, 'yyyy-MM-dd HH:mm:ss.SSS');
    this.inventoryendat = enddate;
    ArrayData.end_at = this.inventoryendat;
    ArrayData.min_bid_amount = Datas.min_bid_amount;
    ArrayData.max_bid_amount = Datas.max_bid_amount;
    ArrayData.approved_status = Datas.approved_status;
    ArrayData.inventory_status = Datas.inventory_status;
    return ArrayData;
  }


  formInvListData(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type,
    subcategory_type, inventory_status, approved_status, Inv_date): AuctionInventoryList {
    const InventoryListData: AuctionInventoryList = {} as AuctionInventoryList;

    InventoryListData.inventory_id = inventory_id;
    InventoryListData.inventory_code = inventory_code;
    InventoryListData.inventory_title = InventoryTitle;
    InventoryListData.inv_type = inv_type;
    InventoryListData.category_type = category_type;
    InventoryListData.subcategory_type = subcategory_type;
    InventoryListData.approved_status = approved_status;
    InventoryListData.inventory_status = inventory_status;
    InventoryListData.cover_image = coverImage;
    InventoryListData.created_at = Inv_date;
    return InventoryListData;

  }

  BindCartData() {
    this.Cartitems = this.Binditems as AuctionCartData[];
    if (this.Cartitems != undefined) {
      if (this.Cartitems.length > 0) {
        this.myStepper.next();
      } else {
        Swal.fire("No Data Added In Cart.");
        this.myStepper.previous();
      }
    }
    else {
      Swal.fire("No Data Added In Cart.");
      this.myStepper.previous();
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  UpdateData() {

    // console.log(this.Cartitems);

    for (let i = 0; i < this.Cartitems.length; i++) {
      if (this.Cartitems.length > this.SaveData.length) {
        this.SaveData.push(this.FormAuctionDetails(this.Cartitems[i].inventory_code, this.Cartitems[i].max_bid_amount, this.Cartitems[i].min_bid_amount, this.Cartitems[i].auction_detail_code, this.Cartitems[i].start_at, this.Cartitems[i].end_at));
      }
    }

    // console.log(this.SaveData);
    //this.firstFormGroup.value.auction_details=this.SaveData;
    const AucMasterData: AuctionMaster = {} as AuctionMaster;
    //AucMasterData.user_id = 1;
    AucMasterData.auction_title = this.firstFormGroup.value.auction_title;
    AucMasterData.bid_type = this.firstFormGroup.value.bid_type;
    AucMasterData.auction_price_type = this.firstFormGroup.value.auction_price_type;
    AucMasterData.auction_type = this.firstFormGroup.value.auction_type;
    AucMasterData.contact_no = this.firstFormGroup.value.contact_no;
    AucMasterData.auction_title = this.firstFormGroup.value.auction_title;
    AucMasterData.start_date = this.datepipe.transform(this.firstFormGroup.value.start_date, 'yyyy-MM-dd');
    AucMasterData.end_date = this.datepipe.transform(this.firstFormGroup.value.end_date, 'yyyy-MM-dd');
    AucMasterData.deposit_amount = this.firstFormGroup.value.DepositAmount;
    //AucMasterData.start_time = this.firstFormGroup.value.start_time;
    AucMasterData.start_time = this.firstFormGroup.value.sHH + ':' + this.firstFormGroup.value.sMM;
    // AucMasterData.end_time = this.firstFormGroup.value.end_time;
    AucMasterData.end_time = this.firstFormGroup.value.eHH + ':' + this.firstFormGroup.value.eMM;
    AucMasterData.state = this.firstFormGroup.value.state.name;
    AucMasterData.district = this.firstFormGroup.value.district;
    AucMasterData.city = this.firstFormGroup.value.city;
    AucMasterData.taluka = this.firstFormGroup.value.taluka;
    AucMasterData.postal_code = this.firstFormGroup.value.postal_code;

    AucMasterData.location_address = this.firstFormGroup.value.location_address;
    AucMasterData.auction_remark = this.firstFormGroup.value.auction_remark;
    AucMasterData.auction_details = this.SaveData;
    AucMasterData.auction_code = this.AAUctionCode;
var json=JSON.stringify(AucMasterData);
console.log(json);
    //  debugger;
    //console.log(AucMasterData + 'save data');
    this.AuctionService.updateAuction(AucMasterData).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }

      if (res.success) {
        this.items = res.data as AuctionMaster[];
        var Check = false;
        //debugger;

        const formData = new FormData();
        // append your data
        formData.append('code', res.data.auction_code);
        formData.append('contract_type', 'auction');
        if (this.CompressImage != "" && this.IsImageChanged == "Yes") {
          // body.set('image', this.imageSrc);
          formData.append('image', this.CompressImage);
          // UploadDocumentData.image=  this.imageSrc;

          Check = true
        }
        if (this.DocumentFile != "" && this.IsDocumentChanged == "Yes") {
          //body.set('document', this.DocumentFile);
          formData.append('document', this.DocumentFile);
          // UploadDocumentData.document=this.DocumentFile;

          Check = true
        }
        //   console.log('formData');
        // console.log(formData);

        if (Check) {

          this.FileUpService.uploadImageDocument(formData).subscribe(data => {
            // console.log('Upload Image document ' + data);
            this.firstFormGroup.reset();
            this.secondFormGroup.reset();
            Swal.fire('Auction Update Successfully!');
            this.router.navigate(['pages/AuctionList'])
          });
        }
        else {

          Swal.fire('Auction Update Successfully!');
          this.router.navigate(['pages/AuctionList'])


        }


      }
      else {
        Swal.fire(res.data.msg, 'Error')
      }

    },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });

  }


  submit() {
    // console.log(this.firstFormGroup.value);
    // console.log(this.secondFormGroup.value);

    for (let i = 0; i < this.Cartitems.length; i++) {
      if (this.Cartitems.length > this.SaveData.length) {
     // this.SaveData.push(this.FormAuctionDetails(this.Cartitems[i].inventory_code, this.Cartitems[i].max_bid_amount, this.Cartitems[i].min_bid_amount, '', this.Cartitems[i].start_at, this.Cartitems[i].end_at));
        this.SaveData.push(this.FormAuctionDetails(this.Cartitems[i].inventory_code, this.Cartitems[i].max_bid_amount, this.Cartitems[i].min_bid_amount, null, this.Cartitems[i].start_at, this.Cartitems[i].end_at));
      }
    }
    //this.firstFormGroup.value.auction_details=this.SaveData;
    const AucMasterData: AuctionMaster = {} as AuctionMaster;
    //AucMasterData.user_id = 1;
    AucMasterData.auction_title = this.firstFormGroup.value.auction_title;
    AucMasterData.bid_type = this.firstFormGroup.value.bid_type;
    AucMasterData.auction_type = this.firstFormGroup.value.auction_type;
    AucMasterData.auction_price_type = this.firstFormGroup.value.auction_price_type;
    AucMasterData.contact_no = this.firstFormGroup.value.contact_no;
    AucMasterData.auction_title = this.firstFormGroup.value.auction_title;
    AucMasterData.start_date = this.datepipe.transform(this.firstFormGroup.value.start_date, 'yyyy-MM-dd');
    AucMasterData.end_date = this.datepipe.transform(this.firstFormGroup.value.end_date, 'yyyy-MM-dd');
    AucMasterData.deposit_amount = this.firstFormGroup.value.DepositAmount;
    //AucMasterData.start_time = this.firstFormGroup.value.start_time;
    AucMasterData.start_time = this.firstFormGroup.value.sHH + ':' + this.firstFormGroup.value.sMM;
    // AucMasterData.end_time = this.firstFormGroup.value.end_time;
    if(this.firstFormGroup.value.auction_type == 'CONTINUOUS'){
    AucMasterData.end_time = this.firstFormGroup.value.eHH + ':' + this.firstFormGroup.value.eMM;
    }
    else{
      AucMasterData.end_time = "";
    }
    AucMasterData.state = this.firstFormGroup.value.state.name;
    AucMasterData.district = this.firstFormGroup.value.district;
    AucMasterData.city = this.firstFormGroup.value.city;
    AucMasterData.taluka = this.firstFormGroup.value.taluka;
    AucMasterData.postal_code = this.firstFormGroup.value.postal_code;
    // AucMasterData.state = 'Maharashtra';
    // AucMasterData.district = 'mumbai suburban';
    // AucMasterData.city = 'mumbai';
    // AucMasterData.taluka = 'mumbai suburban';
    // AucMasterData.postal_code = '400002';
    AucMasterData.location_address = this.firstFormGroup.value.location_address;
    AucMasterData.auction_remark = this.firstFormGroup.value.auction_remark;
    AucMasterData.auction_details = this.SaveData;


    
    var json=JSON.stringify(AucMasterData);
    console.log(json + 'save data');
    this.AuctionService.AddAuction(AucMasterData).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }

      if (res.success) {
        this.items = res.data as AuctionMaster[];
        var Check = false;

        const formData = new FormData();
        // append your data
        formData.append('code', res.data.auction_code);
        formData.append('contract_type', 'auction');
        if (this.CompressImage != "") {
          // body.set('image', this.imageSrc);
          formData.append('image', this.CompressImage);
          // UploadDocumentData.image=  this.imageSrc;

          Check = true
        }
        if (this.DocumentFile != "") {
          //body.set('document', this.DocumentFile);
          formData.append('document', this.DocumentFile);
          // UploadDocumentData.document=this.DocumentFile;

          Check = true
        }
        if (Check) {

          this.FileUpService.uploadImageDocument(formData).subscribe(data => {
            // console.log('Upload Image document ' + data);
            this.firstFormGroup.reset();
            this.secondFormGroup.reset();
            Swal.fire('Auction Saved Successfully!');
            this.router.navigate(['pages/AuctionList'])
          });
        }
        else {


        }


      }
      else {
        Swal.fire(res.data.msg, 'Error')
      }

    },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });

  }


  FormAuctionDetails(InvCode, maxbidamout, minbidamout, auction_detail_code, start_at, end_at): auction_details {
    const AucArrayData: auction_details = {} as auction_details;
    debugger;
    AucArrayData.inventory_code = InvCode;
    AucArrayData.min_bid_amount = minbidamout;
    AucArrayData.max_bid_amount = maxbidamout;
    AucArrayData.auction_detail_code = auction_detail_code;
    AucArrayData.start_at = this.datepipe.transform(start_at, 'yyyy-MM-dd HH:mm:ss.SSS');//start_at;
    AucArrayData.end_at = this.datepipe.transform(end_at, 'yyyy-MM-dd HH:mm:ss.SSS');//end_at;

    return AucArrayData;
  }

  onSelectDocument(event) {

    this.IsDocumentChanged = "Yes";

    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
      const reader = new FileReader();
      const file = event.target.files[0];
      this.DocumentFile = file;
      this.AttachementFile = file.name;
      //console.log(this.DocumentFile);
      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
          // this.DocumentFile = target.result;

        }
      }
      else {
        Swal.fire('Oops...', 'Upload only 5 MB size files!', 'error')
      }

    }

  }
  createAuthHeader(headers: Headers) {
    headers.append('Content-Type', 'application/json');
  }


  onSelectFile(event) {

    this.IsImageChanged = "Yes";

    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
      //  if (Extension == 'JPEG' || Extension == 'jpg' || Extension == 'png') {
      const reader = new FileReader();
      const file = event.target.files[0];
      this.CompressImage = file;


      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
          this.imageCompress.compressFile(target.result, -2, 50, 50).then(
            result => {
              var imageBase64 = result;
              // console.log(result);
              var binary = this.base64toBlob(result, 'image/jpeg');
              var blob_ = new Blob([binary], { type: 'image/jpeg' });
              var file = new File([blob_], 'test.jpg', { type: 'image/jpeg' });
              const reader = new FileReader();
              const filecompress = file;
              reader.readAsDataURL(filecompress);

              reader.onload = (event) => {
                let target: any = event.target;
                this.imageSrc = imageBase64;

              }

            }
          );

        }

      }
      else {
        Swal.fire('Oops...', 'Upload only 5 MB size files!', 'error')
      }



    }

  }
  onItemDeleted(index: number) {
    this.imgurls.splice(index, 1);
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


  openDialog(value): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = value;
    let dialogRef = this.dialog.open(ImagePopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  updateValue(event, cell, rowIndex) {
    //  console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }


  ApproveAction(data: any = {}, isNew?) {
    this.confirmService.confirm({ message: `Approve ${this.firstFormGroup.value.auction_title}?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          //objData.id = data.auction_id.toString();
          objData.id = this.auctionId;
          // alert(Id)
          this.AuctionService.ApproveAction(objData)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                Swal.fire('Auction Approved Successfully!');
                // const AuctionMasterData: AuctionMaster = {} as AuctionMaster;
                // AuctionMasterData.user_id = 1;
                // AuctionMasterData.offset = 0;
                // this.getItems(AuctionMasterData)
                this.router.navigateByUrl('pages/AuctionList');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })
  }


  InactiveData(data: any = {}, isNew?) {

    // console.log(this.AAUctionCode);
    // console.log(data.inventory_code);




    this.confirmService.confirm({ message: `Inactive this Invetory ? ` })
      .subscribe(res => {
        if (res) {
          var Json = {
            "auction_code": this.AAUctionCode,
            "inventory_code": data.inventory_code
          }

          this.AuctionService.InactiveActionInventory(Json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                this.removeItem(data)
                Swal.fire('Inactive  Successfully!');

                //this.router.navigateByUrl('pages/AuctionList');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })
  }

  compareTwoDates() {

    //debugger;
    const AucMasterData: AuctionMaster = {} as AuctionMaster;

    AucMasterData.auction_title = this.firstFormGroup.value.auction_title;
    AucMasterData.bid_type = this.firstFormGroup.value.bid_type;
    AucMasterData.auction_price_type = this.firstFormGroup.value.auction_price_type;
    AucMasterData.contact_no = this.firstFormGroup.value.contact_no;
    AucMasterData.auction_title = this.firstFormGroup.value.auction_title;
    AucMasterData.start_date = this.datepipe.transform(this.firstFormGroup.value.start_date, 'yyyy-MM-dd');
    AucMasterData.end_date = this.datepipe.transform(this.firstFormGroup.value.end_date, 'yyyy-MM-dd');
    AucMasterData.deposit_amount = this.firstFormGroup.value.DepositAmount;
    //AucMasterData.start_time = this.firstFormGroup.value.start_time;
    AucMasterData.start_time = this.firstFormGroup.value.sHH + ':' + this.firstFormGroup.value.sMM;
    // AucMasterData.end_time = this.firstFormGroup.value.end_time;
    AucMasterData.end_time = this.firstFormGroup.value.eHH + ':' + this.firstFormGroup.value.eMM;
    AucMasterData.state = this.firstFormGroup.value.state.name;
    AucMasterData.district = this.firstFormGroup.value.district;
    AucMasterData.city = this.firstFormGroup.value.city;
    AucMasterData.taluka = this.firstFormGroup.value.taluka;
    AucMasterData.postal_code = this.firstFormGroup.value.postal_code;
    // AucMasterData.state = 'Maharashtra';
    // AucMasterData.district = 'mumbai suburban';
    // AucMasterData.city = 'mumbai';
    // AucMasterData.taluka = 'mumbai suburban';
    // AucMasterData.postal_code = '400002';
    AucMasterData.location_address = this.firstFormGroup.value.location_address;
    AucMasterData.auction_remark = this.firstFormGroup.value.auction_remark;
    AucMasterData.auction_details = this.SaveData;
    AucMasterData.auction_type = this.firstFormGroup.value.auction_type;
    var json = JSON.stringify(AucMasterData)


    
    // if (new Date(this.firstFormGroup.controls['end_date'].value) < new Date(this.firstFormGroup.controls['start_date'].value)) {
if(AucMasterData.auction_type!="CONTINUOUS"  ){
  var check = true;
    if (new Date(this.firstFormGroup.controls['end_date'].value) < new Date(this.firstFormGroup.controls['start_date'].value)) {
      this.error = { isError: true, errorMessage: 'End Date cant before start date' };
      //   this.firstFormGroup.controls['end_date'].value("");
      //
      check = false;
      Swal.fire("End Date can't before start date!");
      //return true;
    }
    else if (this.CompressImage == undefined) {
      check = false;
      Swal.fire("Please Upload Auction Image.");
      //return true;
    }
    else if (this.DocumentFile == undefined) {
      check = false;
      Swal.fire("Please Upload Auction Document.");
      //  return true;
    }
  }



    if (check = true) {
      return false;
    }
    else {
      this.myStepper.previous();
      return true;
    }
  }
  CheckData() {
    if (this.Cartitems != undefined) {
      if (this.Cartitems.length > 0) {

      } else {
        Swal.fire("No Data Added In Cart.");
        this.myStepper.previous();
      }
    }
    else {
      Swal.fire("No Data Added In Cart.");
      this.myStepper.previous();
    }
  }

}
