import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { TransactionService } from '../../../shared/services/MyServices/transaction.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserRegistration } from 'app/shared/models/user-registration';
import { ApproveData } from 'app/shared/models/models';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service';
import {Page} from '../../../../../src/app/shared/models/PaginationPage';
@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss'],
  animations: egretAnimations
})
export class RefundListComponent implements OnInit {
  page = new Page();
  BuyerListData: UserRegistration[];
  public items: any[];
  temp = [];
  public getItemSub: Subscription;
  constructor(private router: Router,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private TransService: TransactionService,
    private DataPassServic: DataPassService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.totalElements=0;
   }

  ngOnInit() {
    this.GetRefundList(0)


  }

  GetRefundList(offset) {
    const ObjData: RefundList = {} as RefundList;
   ObjData.offset = offset;
    this.getItemSub = this.TransService.GetRefundList(ObjData)
      .subscribe(data => {
     
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
          this.page.totalElements=data.page_count;
          this.items = this.temp = data.data;
      
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }
      });


  }

  checkcolor(Color) {
 if (Color == "APPROVE") {
      return 'green';
    }
    else {
      return 'red';
    }

  }


  openPopUp(data: any = {}, isNew?) {
 this.DataPassServic.setRefundData(data);
    this.router.navigateByUrl('pages/RefundMaster');
  }

  deleteItem(row) {

  }

 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    columns.splice(columns.length - 1);
   if (!columns.length)
      return;
const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }
  setPage(pageInfo) {
    var offset= pageInfo.offset;
      this.GetRefundList(offset)
      }
}


export interface RefundList {
  offset: number;
  }