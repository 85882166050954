import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {

  public PageDetails = [];
  InventoryImages = [];
  public ServiceFinalData: any[];
  public ServicemenuItems: any[]
  FinaldataData = [];
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  loginData: any;

  constructor(
  ) { }
  ngOnInit() {
    
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    // list.forEach(function (x) { delete x.is_active });
    //list.forEach(function (x) { delete x.role_id });
    this.ServicemenuItems = list;
    for (let entry of this.ServicemenuItems) {
      if (entry.page_detail.length > 0) {
        this.InventoryImages = [];
        for (let entryDetail of entry.page_detail) {
          this.InventoryImages.push(this.formDetailData(entryDetail.page_display_name, entryDetail.page_url));
        }
      }


      this.FinaldataData.push(this.formData(entry.page_display_name
        , entry.type,
        entry.tooltip,
        entry.icon,
        entry.page_url,
        this.InventoryImages
      ));

      this.InventoryImages = [];
    }
    this.addServiceMenu();
  }

  formDataMaster(name, type, tooltip, icon, state): Page {
    const PageData: Page = {} as Page;
    PageData.icon = icon
    PageData.name = name;
    PageData.state = state;

    PageData.tooltip = tooltip;
    PageData.type = type;
    return PageData;
  }

  formData(name, type, tooltip, icon, state, sub: any): Page {
    const PageData: Page = {} as Page;
    PageData.icon = icon
    PageData.name = name;
    PageData.state = state;
    PageData.sub = sub;
    PageData.tooltip = tooltip;
    PageData.type = type;
    return PageData;
  }
  formDetailData(name, state): sub {
    const subData: sub = {} as sub;
    subData.name = name;
    subData.state = state;
    return subData;
  }

  addServiceMenu() {
    this.menuItems = this.FinaldataData;
    this.loginData = JSON.parse(localStorage.getItem("loginData"))
    
    // for(let i = 0; i < this.menuItems.length; i++){
    //    if(this.menuItems[i].name == "Product"){
    //     this.menuItems[i].sub.push({name: 'Add Product', state: 'ProductList'})
    //     this.menuItems[i].sub.push({name: 'Permission', state: 'PermissionList'})
    //    }    
    // }
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        { name: 'SUBITEM', state: 'cards' },
        { name: 'SUBITEM', state: 'buttons' }
      ]
    });
  }
}

export class Page {

  name: string;
  type: string;
  tooltip: string;
  icon: string;
  state: string;
  sub: sub[];
  pageNumber: number;
  size: number;
  totalElements: number;

}


export class sub {
  name: string;
  state: string;
}