import { Component, OnInit } from '@angular/core';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApproveRefundData } from 'app/shared/models/models';

import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { Subscription } from 'rxjs';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { TransactionService } from 'app/shared/services/MyServices/transaction.service';
import Swal from 'sweetalert2';
import { egretAnimations } from "../../../shared/animations/egret-animations";

@Component({
  selector: 'app-refund-master',
  templateUrl: './refund-master.component.html',
  styleUrls: ['./refund-master.component.scss'],
  animations: egretAnimations
})
export class RefundMasterComponent implements OnInit {
  ViewDocument: any;
  constructor(private DataPassServic: DataPassService,
    private confirmService: AppConfirmService,
    private TransService: TransactionService,
    private fb: FormBuilder,
    private router: Router) { }

  public DataPassService: any;
  public itemForm: FormGroup;
  public Id: string;
  public Status: string;
  ngOnInit() {

    this.DataPassService = this.DataPassServic.getRefundData();
if (this.DataPassService.deposit_details_id == undefined) {

      this.router.navigateByUrl('pages/RefundList');
    }
    else {

      this.buildItemForm(this.DataPassService)
    }




  }

 

  buildItemForm(item) {
    this.Id = item.deposit_details_id;
    this.Status = item.status;
    this.ViewDocument = item.deposit_document_path;


    this.itemForm = this.fb.group({
      username: [item.user_name || ''],
      first_name: [item.user.first_name || ''],
      last_name: [item.user.last_name || ''],
      email_id: [item.user.email_id || ''],
      phone_number: [item.user.mobile_no || ''],
      AuctionStatus: [item.status || ''],
      DepositeAmount: [item.deposit_amount || ''],
      DepositeDetailId: [item.deposit_details_id || ''],
      Status: [item.status || '']

    })
  }

  Clear() {
    this.router.navigateByUrl('pages/RefundList');

  }

  ApproveRefund(data: any = {}, isNew?) {

    this.confirmService.confirm({ message: `Are You Sure to Approve This Refund?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveRefundData = {} as ApproveRefundData;
          objData.deposit_id = data.toString();
          objData.status = "APPROVE";
          // alert(Id)
          this.TransService.ApproveRefund(objData)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
               return;
              }
              if (data.success = true) {
                this.router.navigateByUrl('pages/RefundList');
                Swal.fire('Refund Approved Successfully!');
              }
              else {
                Swal.fire(data.data.msg);
              }
            });
        }
      })
  }


}
