import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { Bid } from '../../../shared/models/bid';
import { BidService } from '../../../shared/services/MyServices/bid.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';
import {CommonService} from '../../../shared/services/MyServices/common.service'


@Component({
  selector: 'app-bid-list',
  templateUrl: './bid-list.component.html',
  styleUrls: ['./bid-list.component.scss'],
  animations: egretAnimations
})
export class BidListComponent implements OnInit {

  public items: any[];
  temp = [];
  SessionData : any;
  UserCode : any;

  public getItemSub: Subscription;
  constructor(private router: Router,
    private BidService: BidService,
    private DataPassServic: DataPassService,
    private CommonService : CommonService,
  ) { }

  ngOnInit() {
   this.SessionData=this.CommonService.getUserDetails();
   console.log('this.SessionData');
   console.log(this.SessionData.user_code);
    this.getBidList()

  }
  getBidList() {
    const bidData: Bid = {} as Bid;
    bidData.user_code="CUST00001";
    bidData.dealer_code="DISTCODE";
    this.UserCode=this.SessionData.user_code;
    var json  = {"user_code":this.UserCode,"offset":0,"auction_type": "running" }
    this.getItemSub = this.BidService.BidList(json)
      .subscribe(data => {
       if (data instanceof HttpErrorResponse) {
          return;
        }
          if(data.success=true){
            // console.log(' data.auctions');
            // console.log( data.auctions);
           this.items =this.temp = data.data as Bid[];
          // console.log( this.items);

          }
          else{
            Swal.fire(data.data.msg, 'Error')
          }
         
      });
  }


  openPopUp(data: any = {}, isNew?) {
    //this.items=data;
    //console.log(data);
    this.DataPassServic.setBidInventoryata(data);


    localStorage.setItem('BidAuctionCode', data.auction_code);
    localStorage.setItem('Bidtype', 'Current');

    this.router.navigateByUrl('pages/BidInventory');
      }
      deleteItem(row) {}
      
      updateFilter(event) {
        const val = event.target.value.toLowerCase();
        var columns = Object.keys(this.temp[0]);
        // Removes last "$$index" from "column"
        columns.splice(columns.length - 1);
    
        // console.log(columns);
        if (!columns.length)
          return;
    
        const rows = this.temp.filter(function(d) {
          for (let i = 0; i <= columns.length; i++) {
            let column = columns[i];
            // console.log(d[column]);
            if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
              return true;
            }
          }
        });
    
        this.items = rows;
      }
}
