import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../../shared/directives/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { AuctionService } from '../../../../shared/services/MyServices/auction.service';
import { AuctionInventoryList, auction_details, AuctionMaster, AuctionCartData, ControlDetail, InventoryList, ApproveData } from '../../../../shared/models/models';
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-auction',
  templateUrl: './add-auction.component.html',
  styleUrls: ['./add-auction.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class AddAuctionComponent implements OnInit {
  AAUctionCode: any;
  AddAuctionForm:FormGroup;
  data:any;
  items:any;
  regConfig:any;
  InventoryTitle:string;
  InventoryCode:string;
  selectedAuctionCode: string;
  AuctionListTypes = [];
  todayDate = new Date();
  AuctionServicedata:any
  Binditems = [];
  SaveData = [];
  public Cartitems: any[];
  InventoryImagesService = [];

  constructor( private AuctionService: AuctionService,private router: Router,public dialog: MatDialog,
    private fb:FormBuilder,private datepipe: DatePipe,
    private dialogRef: MatDialogRef<AddAuctionComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
     // console.log('PopUp Data');
      console.log( data);
     
    this.regConfig = data
    this.InventoryTitle=data.InventoryTitle;
    this.InventoryCode=data.inventory_code;
  
  }

  ngOnInit() { 
    this.todayDate.setDate(this.todayDate.getDate())
    this.BindAuctionList()
     this.AddAuctionForm = this.fb.group({
      auction_codetype: ['', Validators.required],
      start_at: ['', Validators.required],
      start_time: ['', Validators.required],
      min_bid_amount: ['', Validators.required],
      max_bid_amount: ['', Validators.required],
  });
  //this.todayDate.setDate(this.todayDate.getDate())
  }
  BindAuctionList(){
    this.AuctionListTypes=[];
   this.AuctionService.BindAuctionData('')
    .subscribe(data => {
      if (data instanceof HttpErrorResponse) {
        return;
      }

      if (data.success) {
      this.AuctionListTypes= data.data ;
      
      }
      else {
        Swal.fire(data.data.msg, 'Error')
      }
    }, error => {
      Swal.fire(error.error.data.msg);
      console.log(error);
    });
  }
  changeAuctionDetail(data) {
    //this.navService.publishNavigationChange(data.value);
    this.AAUctionCode=data.value;
    this.serviceData(this.AAUctionCode);
  }

  serviceData(auction_code)
  {
    let json = '{"auction_code":"'+ auction_code +'"}';
       this.AuctionService.ViewAuctionByCode(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success) {
        this.AuctionServicedata=data.data;
        this.buildItemForm(this.AuctionServicedata);
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      }, error => {
        Swal.fire(error.error.data.msg);
        console.log(error);
      });
  }

  buildItemForm(item) {
    
      var ServiceImage = "";
      
      
    
      var TempstartTime = item.start_time
      var splitsstarytime = TempstartTime.split(":")

      if(item.auction_type != 'CONTINUOUS'){
      var TempEndTime = item.end_time
      var splitsEndtime = TempEndTime.split(":")
      }



    

      

      for (let entry of item.auction_detail) {

        this.InventoryImagesService = entry.inventory_documents.images;
        if (this.InventoryImagesService.length > 0) {

          var images = this.InventoryImagesService.find(x => x.is_cover === true);
          if (images == '' || images == undefined || images == null) {
            var images = this.InventoryImagesService[0];
            ServiceImage = images.path;
          }
          else {
            ServiceImage = images.path;
          }
        }
       
        this.Binditems.push(this.formDataEdit(
          entry.inventory, entry.inventory_code, entry.inventory_title, 
          ServiceImage, "", entry.cat_name, entry.sub_cat_name, "", "", entry.start_at,entry.end_at,entry.min_bid_amount, entry.max_bid_amount, entry.auction_detail_code,
          entry.total_bids  ));
      }

    }


  formDataEdit(inventory_id, inventory_code, inventory_title, cover_image, inv_type, category_type, subcategory_type, inventory_status, approved_status,
    start_at,end_at,min_bid_amount, max_bid_amount, auction_detail_code, total_bids): AuctionCartData {
    const ArrayData: AuctionCartData = {} as AuctionCartData;
    ArrayData.inventory_id = inventory_id;
    ArrayData.inventory_code = inventory_code;
    ArrayData.inventory_title = inventory_title;
    ArrayData.cover_image = cover_image;
   ArrayData.category_type = category_type;
    ArrayData.subcategory_type = subcategory_type;
    ArrayData.start_at = this.datepipe.transform(start_at, 'yyyy-MM-dd HH:mm:ss.SSS');
    ArrayData.end_at = this.datepipe.transform(end_at, 'yyyy-MM-dd HH:mm:ss.SSS');
    ArrayData.min_bid_amount = min_bid_amount;
    ArrayData.max_bid_amount = max_bid_amount;
    ArrayData.auction_detail_code = auction_detail_code;
    ArrayData.total_bids = total_bids;
    return ArrayData;
  }


  inventorystartat: string;
  inventoryendat: string;
  formData(Datas): AuctionCartData {
    const ArrayData: AuctionCartData = {} as AuctionCartData;
    ArrayData.inventory_id = this.regConfig.inventory_id;
    ArrayData.auction_detail_code = "";
    ArrayData.inventory_code = this.regConfig.inventory_code;
    ArrayData.inventory_title = this.regConfig.InventoryTitle;
    ArrayData.cover_image =this.regConfig.is_cover;
    ArrayData.category_type = this.regConfig.cat_name;
    ArrayData.subcategory_type = this.regConfig.sub_cat_name;
    this.inventorystartat = this.datepipe.transform(Datas.start_at, 'yyyy-MM-dd')  + ' ' + Datas.start_time;
   ArrayData.start_at = this.datepipe.transform(this.inventorystartat, 'yyyy-MM-dd HH:mm:ss.SSS');
    var startdate = new Date(this.inventorystartat);
    startdate.setHours(startdate.getHours() + 72);
    var enddate = this.datepipe.transform(startdate, 'yyyy-MM-dd HH:mm:ss.SSS');
    this.inventoryendat = enddate;
    ArrayData.end_at = this.inventoryendat;
    ArrayData.min_bid_amount = Datas.min_bid_amount;
    ArrayData.max_bid_amount = Datas.max_bid_amount;
    ArrayData.total_bids = "0";
return ArrayData;
  }



  submitForm($event: any) {
    if (this.AddAuctionForm.invalid) {
      Swal.fire("Please fill in the required fields", "");
      return;
    }
    
    var existItem = this.Binditems.find(x => x.inventory_code == this.regConfig.inventory_code);
    if (existItem) {
      Swal.fire('Inventory  Already Added!')
      return false;
    }
    var maxbidamout = this.AddAuctionForm.value.max_bid_amount;
    var minbidamout = this.AddAuctionForm.value.min_bid_amount;

    if (parseInt(maxbidamout) < parseInt(minbidamout)) {
      Swal.fire('', 'Min Amount should be less than Max Amount!', 'error');
      return;
    }
   

var add=this.AddInventory(this.AddAuctionForm.value);

    this.Cartitems = this.Binditems;
    for (let i = 0; i < this.Cartitems.length; i++) {
      if (this.Cartitems.length > this.SaveData.length) {
        this.SaveData.push(this.FormAuctionDetails(this.Cartitems[i].inventory_code, this.Cartitems[i].max_bid_amount, this.Cartitems[i].min_bid_amount, this.Cartitems[i].auction_detail_code, this.Cartitems[i].start_at, this.Cartitems[i].end_at));
      }
    }

    // console.log(this.SaveData);
    //this.AuctionServicedata.auction_details=this.SaveData;
    const AucMasterData: AuctionMaster = {} as AuctionMaster;
    //AucMasterData.user_id = 1;
    AucMasterData.auction_title = this.AuctionServicedata.auction_title;
    AucMasterData.bid_type = this.AuctionServicedata.bid_type;
    AucMasterData.auction_price_type = this.AuctionServicedata.auction_price_type;
    AucMasterData.auction_type = this.AuctionServicedata.auction_type;
    AucMasterData.contact_no = this.AuctionServicedata.contact_no;
    AucMasterData.auction_title = this.AuctionServicedata.auction_title;
    AucMasterData.start_date = this.datepipe.transform(this.AuctionServicedata.start_date, 'yyyy-MM-dd');
    AucMasterData.end_date = this.datepipe.transform(this.AuctionServicedata.end_date, 'yyyy-MM-dd');
    AucMasterData.deposit_amount = this.AuctionServicedata.DepositAmount;
       AucMasterData.start_time = this.AuctionServicedata.start_time;
   
    AucMasterData.end_time = this.AuctionServicedata.end_time;
    AucMasterData.state = this.AuctionServicedata.state;
    AucMasterData.district = this.AuctionServicedata.district;
    AucMasterData.city = this.AuctionServicedata.city;
    AucMasterData.taluka = this.AuctionServicedata.taluka;
    AucMasterData.postal_code = this.AuctionServicedata.postal_code;

    AucMasterData.location_address = this.AuctionServicedata.location_address;
    AucMasterData.auction_remark = this.AuctionServicedata.auction_remark;
    AucMasterData.auction_details = this.SaveData;
    AucMasterData.auction_code = this.AAUctionCode;
var json=JSON.stringify(AucMasterData);
console.log(json);
  
    this.AuctionService.updateAuction(AucMasterData).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }

      if (res.success) {
        this.items = res.data as AuctionMaster[];
        this.AddAuctionForm.reset();
        this.dialog.closeAll();
        Swal.fire('Inventory Added in Auction Successfully!');
          this.router.navigate(['pages/InventoryList'])



      }
      else {
        Swal.fire(res.data.msg, 'Error')
      }

    },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });


  }

  AddInventory(selectedItem: any) {
   
    var maxbidamout = selectedItem.max_bid_amount;
    var minbidamout = selectedItem.min_bid_amount;
    if (maxbidamout != "" && minbidamout != "" && selectedItem.start_at && selectedItem.start_time) {

      var existItem = this.Binditems.find(x => x.inventory_code == selectedItem.inventory_code);
      if (existItem) {
        Swal.fire('Inventory', 'Inventory  Already Added!')
        return false;
      }
      else {
        if (parseInt(maxbidamout) < parseInt(minbidamout)) {
          Swal.fire('', 'Min Amount should be less than Max Amount!', 'error');
          return false;
        }
        else {
          this.Binditems.push(this.formData(selectedItem));
          maxbidamout = "";
          minbidamout = "";
          return true;
        }
      }
    }
    else {
      Swal.fire('', 'Enter all input');
      return false;
    }

  }

  FormAuctionDetails(InvCode, maxbidamout, minbidamout, auction_detail_code, start_at, end_at): auction_details {
    const AucArrayData: auction_details = {} as auction_details;
    AucArrayData.inventory_code = InvCode;
    AucArrayData.min_bid_amount = minbidamout;
    AucArrayData.max_bid_amount = maxbidamout;
    AucArrayData.auction_detail_code = auction_detail_code;
    AucArrayData.start_at = this.datepipe.transform(start_at, 'yyyy-MM-dd HH:mm:ss.SSS');//start_at;
    AucArrayData.end_at = this.datepipe.transform(end_at, 'yyyy-MM-dd HH:mm:ss.SSS');//end_at;

    return AucArrayData;
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.AddAuctionForm.reset();
    for (const key in this.AddAuctionForm.controls) {
      if (this.AddAuctionForm.controls.hasOwnProperty(key)) {
        this.AddAuctionForm.controls[key].markAsPristine();
        this.AddAuctionForm.controls[key].updateValueAndValidity();
      }
    }

  }

}
