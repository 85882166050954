import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import Swal from 'sweetalert2';
import { RegistrationService } from 'app/shared/services/MyServices/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { I } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-buyer-registration',
  templateUrl: './buyer-registration.component.html',
  styleUrls: ['./buyer-registration.component.scss'],
  animations: egretAnimations,
})
export class BuyerRegistrationComponent implements OnInit {
  StateArray: any[];
  DistictArray: any[];
  CityArray: any[];
  TalukaArray: any[];
  PincodeArray: any[];

  stateCode: string;
  stateName: string;
  districtName: string;
  cityName: string;
  talukaName: string;

  BuyerRegForm: FormGroup;
  readable: any;
  constructor(private RegService: RegistrationService, private router: Router,
    private commonService: CommonService) { }
    isPageUrlCheck:any;
  ngOnInit() {
    // Check Page Access
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'BuyerReg') {
            this.isPageUrlCheck = "BuyerReg"
            this.readable= entryDetail.readable
          }
        }
      }
    }

    if(  this.isPageUrlCheck == "BuyerReg"){
      this.commonService.BindState('').pipe(first()).subscribe(res => {
        if (res instanceof HttpErrorResponse) {
          return;
        }
        if (res.length > 0) {
          this.StateArray = res;
          this.DistictArray = [];
          this.CityArray = [];
          this.TalukaArray = [];
          this.PincodeArray = [];
        }
        else {
          const errorMessage = res;
          console.log(errorMessage);
          return;
        }
      },
        error => {
          console.log(error);
        })
  
      //Bind State
      this.BuyerRegForm = new FormGroup({
        party_type: new FormControl('', [Validators.required]),
        party_sub_type: new FormControl('', [Validators.required]),
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        mobile_no: new FormControl('', [Validators.required]),
        email_id: new FormControl('', [Validators.required]),
        address1: new FormControl('', [Validators.required]),
        address2: new FormControl('', [Validators.required]),
        state: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        district: new FormControl('', [Validators.required]),
        taluka: new FormControl('', [Validators.required]),
        postal_code: new FormControl('', [Validators.required]),
  
        //   password: new FormControl('', [Validators.required]),
  
      })
    }
    else{
      this.router.navigate(["pages/ErrorPage"]);
    }

    
  }



  onSelectState(value) {
    this.stateCode = value.code;
    this.stateName = value.name;
    this.BuyerRegForm.value.state = value.code;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"}}';
    this.commonService.BindDistrict(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.DistictArray = res;
        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }



  onSelectDistrict(value) {
    this.districtName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '"}';
    this.commonService.BindCity(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.CityArray = res;
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  onSelectCity(value) {
    this.cityName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '"}';
    this.commonService.BindTaluka(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.TalukaArray = res;
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }

  onSelectTaluka(value) {
    this.talukaName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '","taluka":"' + this.talukaName + '"}';
    this.commonService.BindPincode(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.pincodes.length > 0) {
        this.PincodeArray = res.pincodes;
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }
  onSelectParty() {

  }



  btnsubmitForm() {

    this.RegService.BuyerRegistration(this.BuyerRegForm.value).pipe().subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }

      if (res.success) {



        this.BuyerRegForm.reset();
        Swal.fire('Successfully saved!', 'success');
        this.router.navigate(['pages/BuyerUserList'])

      }
      else {
        Swal.fire(res.data.msg, 'Error')
      }

    },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });
  }
  onClear() {
    this.BuyerRegForm.reset;
  }
}

