import { Component, OnInit, DebugElement } from '@angular/core';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';
import { Subscription } from 'rxjs';
import { BidService } from '../../../shared/services/MyServices/bid.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { Router } from '@angular/router';
import { CommonService } from 'app/shared/services/MyServices/common.service';



@Component({
  selector: 'app-bid-settelment',
  templateUrl: './bid-settelment.component.html',
  styleUrls: ['./bid-settelment.component.scss'],
  
  animations: egretAnimations
})
export class BidSettelmentComponent implements OnInit {

  constructor(private DataPassServic: DataPassService, private BidService: BidService,private CommonService:CommonService,



    private router: Router,private commonService: CommonService, ) { }

  public Datas: any;
  public getItem: Subscription;
  public items: any[];
  ErrorData: any[];
  AuctionCode: any
  BidTypeCode: any
  SessionData:any;
  temp = [];
  ngOnInit() {
    this.SessionData=this.CommonService.getUserDetails();

    //console.log(this.Datas.inventory_code);
  

    // this.getBidInventoryList(this.Datas.auction_code);

this.getSettelmentList();


  }


  getSettelmentList() {


    var Usercode
   // Usercode =parseInt(this.CommonService.getUserCode());
    Usercode=this.SessionData.user_code;
    var json = { "user_code": Usercode, "offset": 0 }
   
    this.getItem = this.BidService.BindBidWinner(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
         // console.log(data.data);
          this.items = this.temp = data.data;
          // console.log( this.items);

        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  // openPopUp(data: any = {}, isNew?) {
    
  // }



  BindReason() {
    this.commonService.BindPartyType('').subscribe(
      data => {
        // if (data.success == true) {
        this.ErrorData = data.data;
       

      }, (err) => {
        Swal.fire('Oops...', err.msg, 'error')
      }
    );
  }


  openPopUp(data: any = {}, isNew?) {
    // console.log(data);
    this.DataPassServic.setBidSettelmentData(data);
    this.router.navigateByUrl('pages/SettelMentMaster');
  }

  back() {

  }

}

