import { FormBuilder, FormGroup, Validators,FormArray,AbstractControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { AuctionInventoryList, auction_details, AuctionMaster, AuctionCartData, ControlDetail, InventoryList, ApproveData } from '../../../shared/models/models';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuctionService } from '../../../shared/services/MyServices/auction.service';
import { InventoryService } from '../../../shared/services/MyServices/inventory.service';
import { AuthorizeService } from '../../../shared/services/MyServices/authorize.service';
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../shared/directives/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImagePopupComponent } from '../image-popup/image-popup.component';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service'
import { TataServiceService } from '../../../TataService/tata-service.service';
import { MatStepper } from '@angular/material/stepper';



@Component({
  selector: 'app-aution-test',
  templateUrl: './aution-test.component.html',
  styleUrls: ['./aution-test.component.scss'],
  animations: egretAnimations,
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})

export class AutionTestComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  editing = {};
  userTable: FormGroup;
  control: FormArray;
  
  mode: boolean;
  touchedRows: any;

  public ServiceData: any[];
  FinalData = [];
  InventoryImages = [];
  InventoryImagesService = [];

  error: any = { isError: false, errorMessage: '' };
  
  rows = [];
  todayDate = new Date();
  RefInput: ElementRef;
  disableread: boolean[] = [];
  imageSrc: string;
  public uploader: FileUploader = new FileUploader({ url: '' });
  public hasBaseDropZoneOver: boolean = false;
  console = console;
  buttonDisabled: boolean;
  ListData: InventoryList[];
  temp = [];
  public items: any[];
  public Cartitems: any[];
  Binditems = [];
  SaveData = [];
  imgurls = [];
  CompressImage: any;
  DocumentFile: any;
  btnInventory: any;

  IsDocumentChanged: any;
  IsImageChanged: any;
  //public Cartitems: any[];

  public getItemSub: Subscription;
  public GetData: any;
  public datas: any;
  constructor(private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private commonService: CommonService,
    private AuthService: AuthorizeService,
    private InvenService: InventoryService,
    private datepipe: DatePipe,
    private imageCompress: NgxImageCompressService,
    private FileUpService: FileUploadService,
    private DataPass: DataPassService,
    private TataService: TataServiceService,
    private AuctionService: AuctionService) {
   
  }

  ngOnInit() {
    this.touchedRows = [];
    this.userTable = this.fb.group({
      tableRows: this.fb.array([]),
      NewInventoryData:this.fb.array([]),
    });
    this.addRow();
    this.getItems()
  }
  getItems() {
    this.FinalData = [];

    this.items = null;

    

    const InventoryListrData: InventoryList = {} as InventoryList;
    InventoryListrData.user_id = 1;
    InventoryListrData.offset = 0;
    this.getItemSub = this.AuctionService.AuctionInventoryList(InventoryListrData)
      .subscribe(data => {

        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
          //this.console.log(data.data);
          this.ServiceData = data.data;
          let inventory_id: number;
          let inventory_code, InventoryTitle, coverImage: string;
          let inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date, DealerCode: string;
          //console.log('this.ServiceData');
          //console.log(this.ServiceData);
          for (let entry of this.ServiceData) {


            inventory_id = entry.inventory_id;
            inventory_code = entry.inventory_code;
            inv_type = entry.cat_name;
            category_type = entry.sub_cat_name;
            subcategory_type = entry.sub_cat_detail_name;
            InventoryTitle = entry.inventory_title;
            inventory_status = entry.inventory_status;
            approved_status = entry.approved_status;
            Inv_date = new Date(entry.created_at);
            DealerCode = entry.dealer_code;
            this.InventoryImages = entry.inventory_documents.images;
            if (this.InventoryImages.length > 0) {
              var images = this.InventoryImages.find(x => x.is_cover === true);
            //  debugger;
              if (images == '' || images == undefined || images == null) {
                var images = this.InventoryImages[0];
                coverImage = images.path;
              }
              else {
                coverImage = images.path;
              }
            }
            else {
            }
            //this.FinalData.push(this.formInvListData(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date));
            this.getmultipleControl.push(this.bindValueArray(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date));
          }

          //         this.items = this.temp = data.data as InventoryList[];
          this.items = this.temp = this.FinalData;

          let yFilter = this.Binditems.map(itemY => { return itemY.inventory_id; });

          // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
          let filteredX = this.items.filter(itemX => !yFilter.includes(itemX.inventory_id));

          for (let index in this.userTable.get('NewInventoryData').value) {
         this.editing[index] = false;
          };
          console.log(this.userTable.get('NewInventoryData'))

          this.items = filteredX;



         
          for (let i = 0; i < this.items.length; i++) {
            this.disableread[i] = false;
          }
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      });

    
  }

formInvListData(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type,
    subcategory_type, inventory_status, approved_status, Inv_date): AuctionInventoryList {
    const InventoryListData: AuctionInventoryList = {} as AuctionInventoryList;
    InventoryListData.inventory_id = inventory_id;
    InventoryListData.inventory_code = inventory_code;
    InventoryListData.inventory_title = InventoryTitle;
    InventoryListData.inv_type = inv_type;
    InventoryListData.category_type = category_type;
    InventoryListData.subcategory_type = subcategory_type;
    InventoryListData.approved_status = approved_status;
    InventoryListData.inventory_status = inventory_status;
    InventoryListData.cover_image = coverImage;
    InventoryListData.created_at = Inv_date;
    return InventoryListData;

  }


  ngAfterOnInit() {
    this.control = this.userTable.get('tableRows') as FormArray;
  }

  initiateForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      dob: ['', [Validators.required]],
      bloodGroup: [''],
      mobNumber: ['', [Validators.required, Validators.maxLength(10)]],
      isEditable: [true]
    });
  }

  initiateInventoryForm(): FormGroup {
    return this.fb.group({
      cover_image: ['',],
      inventory_id: [''],
      inventory_code: [''],
      inventory_title: [''],
      category_type: [''],
      subcategory_type: [''],
      start_at: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      end_at: ['', [Validators.required]],
      min_bid_amount: ['', [Validators.required]],
      max_bid_amount: ['', [Validators.required]],
      isEditable: [true]
    });
  }

  get getmultipleControl() {
    return <FormArray>this.userTable.get('NewInventoryData');
  }

  // BindControlArray(data) {
  //   this.userTable['NewInventoryData'] = []
  //   for (let file of data) {
  //    this.getmultipleControl.push(this.bindValueArray(file, ""))
  //   }

  // }

  bindValueArray(inventory_id, inventory_code, InventoryTitle, coverImage, inv_type, category_type, subcategory_type, inventory_status, approved_status, Inv_date) {
    return this.fb.group(
      {
        cover_image: [coverImage],
        inventory_id: [inventory_id],
        inventory_code: [inventory_code],
        inventory_title: [InventoryTitle],
        category_type: [category_type],
        subcategory_type: [subcategory_type],
        start_at: ['', [Validators.required]],
        start_time: ['', [Validators.required]],
        end_at: ['', [Validators.required]],
        min_bid_amount: ['', [Validators.required]],
        max_bid_amount: ['', [Validators.required]],
        isEditable: [true]
      }
    )
  }

  AddInventory(selectedItem: any, rowIndex) {
   // debugger;
    var maxbidamout = selectedItem.max_bid_amount;
    var minbidamout = selectedItem.min_bid_amount;
      if (maxbidamout != "" && minbidamout != "" && selectedItem.start_at && selectedItem.start_time) {

      var existItem = this.Binditems.find(x => x.inventory_code == selectedItem.inventory_code);
      if (existItem) {
        this.editing[rowIndex] = false;
        Swal.fire('Inventory', 'Inventory  Already Added!')
      }
      else {
        if (parseInt(maxbidamout) < parseInt(minbidamout)) {
          Swal.fire('', 'Min Amount should be less than Max Amount!', 'error');
        }
        else {
          this.editing[rowIndex] = true;
          this.Binditems.push(this.formData(selectedItem, maxbidamout, minbidamout));
          maxbidamout = "";
          minbidamout = "";
         Swal.fire('Added To Auction', 'Inventory Added Sucessfully!')
        }
      }
    }
    else {
      Swal.fire('', 'Enter all input');
    }
  }

  inventorystartat:string;
inventoryendat:string;
startdatetime:Date;
  formData(Datas, maxbidamout, minbidamout): AuctionCartData {
    const ArrayData: AuctionCartData = {} as AuctionCartData;
    ArrayData.inventory_id = Datas.inventory_id;
    ArrayData.inventory_code = Datas.inventory_code;
    ArrayData.inventory_title = Datas.inventory_title;
    ArrayData.cover_image = Datas.cover_image;
    ArrayData.category_type = Datas.category_type;
    ArrayData.subcategory_type = Datas.subcategory_type;
    this.inventorystartat=Datas.start_at + ' ' + Datas.start_time;
   ArrayData.start_at = this.inventorystartat  +'.'+0;;
   
    var startdate= new Date(this.inventorystartat);
    var dds=this.getMillies(startdate);
    startdate.setHours(startdate.getHours() + 72);
    var enddate=this.datepipe.transform(startdate, 'yyyy-MM-dd h:mm:ss');
  this.inventoryendat=enddate;
    ArrayData.end_at = this.inventoryendat +'.'+0;
    ArrayData.min_bid_amount = Datas.min_bid_amount;
    ArrayData.max_bid_amount = Datas.max_bid_amount;
   ArrayData.approved_status = Datas.approved_status;
    ArrayData.inventory_status = Datas.inventory_status;
    return ArrayData;
    // const ArrayData: AuctionCartData = {} as AuctionCartData;
    // ArrayData.inventory_id = Datas.inventory_id;
    // ArrayData.inventory_code = Datas.inventory_code;
    // ArrayData.inventory_title = Datas.inventory_title;
    // ArrayData.cover_image = Datas.cover_image;
    // ArrayData.category_type = Datas.category_type;
    // ArrayData.subcategory_type = Datas.subcategory_type;
    // ArrayData.min_bid_amount = minbidamout;
    // ArrayData.max_bid_amount = maxbidamout;
    // return ArrayData;
  }
  getMillies(input: Date): number
  {
      return new Date(input).getMilliseconds();
  }

  months:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
 
  getDateFormat(dateObj:Date){
  //  let dateObj = new Date(dateStr);
    let year = dateObj.getUTCFullYear();
    let month = dateObj.getUTCMonth()+1;
    let date = dateObj.getUTCDate();
    let time = dateObj.getUTCHours() + ':' + dateObj.getUTCMinutes() + ':' +dateObj.getUTCSeconds();

    // let displayDateTime = date + '' + this.months[month] + ' ' + year + ' ' + time;
    let displayDateTime = year + '-' + month + '-' + date + ' ' + time;
    // console.log(displayDateTime);
    return displayDateTime;
  }
  addRow() {
    const control =  this.userTable.get('tableRows') as FormArray;
    control.push(this.initiateForm());
  }

  deleteRow(index: number) {
    const control =  this.userTable.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.userTable.value);
  }

  get getFormControls() {
    const control = this.userTable.get('tableRows') as FormArray;
    return control;
  }

  submitForm() {
    const control = this.userTable.get('tableRows') as FormArray;
    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    console.log(this.touchedRows);
  }

  toggleTheme() {
    this.mode = !this.mode;
  }
}
