import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import Swal from 'sweetalert2';
import { log } from 'util';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];
@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent implements OnInit {
  public permissionForm: FormGroup;
  masterData: any = [];
  displayedColumns: string[] = ['name', 'Status', 'Action', 'Readable'];
  dataSource = ELEMENT_DATA;
  selectedPermissions: any = [];
  partyTypeMasterData: any = [];
  partyTypeSubMasterData: any = [];
  selectedPartyType: any;
  selectedPartySubType: any;
  pagelist: any = [];
  isRead: any;
  fieldReadOnly: any = [];
  constructor(private fb: FormBuilder, private commonservice: CommonService, private loader: AppLoaderService) { }

  ngOnInit() {
    this.buildpermissionForm();
    this.getPartyTypeList();
  }

  buildpermissionForm() {
    this.permissionForm = this.fb.group({
      party_type: [],
      party_sub_type: [],
    })
  }

  getList(json) {
    this.loader.open();
    this.pagelist = [];
    this.masterData = [];
    this.selectedPermissions = [];
    this.commonservice.GetPermissionsPageList(json).subscribe(
      (res) => {
        this.loader.close();
        if (res.success) {
          this.masterData = res.data;
          for (let i = 0; i < this.masterData.length; i++) {
            var foundIndex = this.selectedPermissions.findIndex((item: any) => item == this.masterData[i]);
            if (foundIndex == '-1' && this.masterData[i].page_status) {
              this.selectedPermissions.push(this.masterData[i]);
              this.fieldReadOnly[this.masterData[i].page_master_detail_id] = true;
            }
            else {
              this.fieldReadOnly[this.masterData[i].page_master_detail_id] = false;
            }
          }
          this.masterData = this.masterData.filter((item: any) => item.page_detail_name != "Permission")
          for (let i = 0; i < this.selectedPermissions.length; i++) {
            this.pagelist.push({
              "page_id": this.selectedPermissions[i].page_master_id,
              "page_detail_id": this.selectedPermissions[i].page_master_detail_id,
              "editable": this.selectedPermissions[i].read_edit.ediatble,
              "readable": this.selectedPermissions[i].read_edit.readable
            })
          }

        } else {
          const errorMessage = res;
          // return;
        }
      },
      (err) => {
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }

  getPartyTypeList() {
    this.loader.open();
    this.commonservice.GetPartyTypeList().subscribe(
      (res) => {
        this.loader.close();
        if (res.success) {
          this.partyTypeMasterData = res.data;
        } else {
          const errorMessage = res;
        }
      },
      (err) => {
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }

  getPartySubtypeList(json) {
    this.loader.open();
    this.commonservice.GetPartySubTypeList(json).subscribe(
      (res) => {
        this.loader.close();
        if (res.success) {
          this.partyTypeSubMasterData = res.data;
        } else {
          const errorMessage = res;
        }
      },
      (err) => {
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }

  saveMaster() {
    let json = {
      "party_type": this.selectedPartyType.party_type_id,
      "party_sub_type": this.selectedPartySubType.party_sub_type_id,
      "page_list": this.pagelist
    }
    this.loader.open();
    this.commonservice.UpdatePageStatusList(json).subscribe(
      (res) => {
        this.loader.close();
        if (res.success) {
          Swal.fire(res.data.msg)
          const ResponseData: Input = {} as Input;
          ResponseData.party_type = this.selectedPartyType.party_type_id;
          ResponseData.party_sub_type = this.selectedPartySubType.party_sub_type_id;

          this.getList(ResponseData);
        } else {
          const errorMessage = res;
        }
      },
      (err) => {
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }

  onChecked(element) {
    this.fieldReadOnly[element.page_master_detail_id] = !this.fieldReadOnly[element.page_master_detail_id];
    var foundIndex = this.selectedPermissions.findIndex((item: any) => item == element);
    // var foundIndexMaster = this.masterData.findIndex((item: any) => item == element);
    // if (foundIndexMaster) {
    //   this.masterData[foundIndexMaster].page_status = !this.masterData[foundIndexMaster].page_status;
    // }
    if (foundIndex != '-1') {
      this.selectedPermissions.splice(foundIndex, 1);
    } else {
      this.selectedPermissions.push(element)
    }

    this.pagelist = [];
    for (let i = 0; i < this.selectedPermissions.length; i++) {
      this.pagelist.push({
        "page_id": this.selectedPermissions[i].page_master_id,
        "page_detail_id": this.selectedPermissions[i].page_master_detail_id,
        "editable": this.selectedPermissions[i].read_edit.ediatble,
        "readable": this.selectedPermissions[i].read_edit.readable
      })
    }

  }

  onSelect(value, flag) {
    // let json;
    const ResponseData: Input = {} as Input;
    if (flag == 'PartType') {
      this.selectedPartyType = value;
      this.masterData = [];
      this.permissionForm.controls['party_sub_type'].setValue(null)
      let req = {
        "party_type_id": value.party_type_id
      }
      this.getPartySubtypeList(req);
    }
    else if (flag == 'PartSubType') {
      this.selectedPartySubType = value;
      ResponseData.party_type = this.selectedPartyType.party_type_id;
      ResponseData.party_sub_type = this.selectedPartySubType.party_sub_type_id;

      // if(ResponseData.party_type){
      this.getList(ResponseData);
      // }
    }

  }

  isReadable(rowData, value) {
    this.isRead = value;
    var foundIndex = this.pagelist.findIndex((item: any) => item.page_detail_id == rowData.page_master_detail_id);

    if (foundIndex != '-1') {
      this.pagelist[foundIndex].editable = value == 'edit' ? true : false;
      this.pagelist[foundIndex].readable = value == 'read' ? true : false;
    }
  }

}

export class Input {
  party_type: any;
  party_sub_type: any;
}
