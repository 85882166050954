import { Injectable } from '@angular/core';
import {HttpClient,HttpParams, HttpErrorResponse} from '@angular/common/http'
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, of, throwError, Observable} from 'rxjs';
import {Router} from '@angular/router';
import { environment, } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private GetBuyerRegistrationListUrl = `${environment.TataapiUri}/api/v1/accounts/get_users/`;
  // private GetSellerRegistrationListUrl = `${environment.TataapiUri}/api/v1/accounts/get_tml_users/`;
  private GetSellerRegistrationListUrl = `${environment.TataapiUri}/api/v2/accounts/get_tml_users_by_dealer/`;

  private AdminUserRegUrl = `${environment.TataapiUri}/api/v1/accounts/register/`;
  private BuyerUserRegUrl = `${environment.TataapiUri}/api/v1/front_end/register/`;
  private AdminUserApprove = `${environment.TataapiUri}/api/v1/accounts/Active_User/`;
  constructor(private http:HttpClient,private _Router:Router) { }

  // Buyer Registration Start
  GetBuyeRegistrationList(data:any):Observable<any>
  {
     return this.http.post<any>(this.GetBuyerRegistrationListUrl,data).pipe(catchError(this.handlError));
  }

  BuyerRegistration(data:any):Observable<any>
  {
     return this.http.post<any>(this.BuyerUserRegUrl,data).pipe(catchError(this.handlError));
  }
    // Buyer Registration End

    
  // Admin Registration Start
  GetSellerRegistrationList(data:any):Observable<any>
  {
     return this.http.post<any>(this.GetSellerRegistrationListUrl,data).pipe(catchError(this.handlError));
  }

  AdminRegistration(data:any):Observable<any>
  {
     return this.http.post<any>(this.AdminUserRegUrl,data).pipe(catchError(this.handlError));
  }
// Admin Registration End



//ApproveUser


  ADminUserApprove(data:any):Observable<any>
  {
     return this.http.post<any>(this.AdminUserApprove,data).pipe(catchError(this.handlError));
  }

  handlError(error:HttpErrorResponse)
  {
    return throwError(error)
  }

}
