import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { AuctionMaster } from '../../../shared/models/models';
import { RegistrationService } from '../../../shared/services/MyServices/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: egretAnimations
})
export class UserListComponent implements OnInit {

  AuctionListData: AuctionMaster[];
  public items: any[];
  temp = [];
  public getItemSub: Subscription;
  constructor(private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private RegService: RegistrationService,
  ) { }

  isPageUrlCheck: any;
  ngOnInit() {

    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'Userlist') {
            this.isPageUrlCheck = "Userlist"
          }
        }
      }
    }

    if (this.isPageUrlCheck == "Userlist") {
      this.getUserList()
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }
  }

  getUserList() {

    this.getItemSub = this.RegService.GetBuyeRegistrationList('')
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }
        if (data.success = true) {
          //this.items =this.temp = data.data;
          this.items = this.temp = data;
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }
      });


  }

  openPopUp(data: any = {}, isNew?) {
    console.log(data);
    this.router.navigateByUrl('pages/Auction');
  }

  deleteItem(row) {

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);
    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }
}

