import { Component, OnInit } from '@angular/core';
import { DataPassService } from '../../../shared/services/MyServices/data-pass.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApproveData } from 'app/shared/models/models';

import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { Subscription } from 'rxjs';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { TransactionService } from 'app/shared/services/MyServices/transaction.service';
import Swal from 'sweetalert2';
import { egretAnimations } from "../../../shared/animations/egret-animations";

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.scss'],
  animations: egretAnimations
})
export class BankDetailComponent implements OnInit {
  CancelChequeImage: any;
  PanImage: any;
  GstImage: any;
  constructor(private DataPassServic: DataPassService,
    private confirmService: AppConfirmService,
    private TransService: TransactionService,
    private fb: FormBuilder,
    private router: Router) { }

  public DataPassService: any;
  public itemForm: FormGroup;
  public Id: string;
  public Status: string;

  ngOnInit() {
    this.DataPassService = this.DataPassServic.getBankDetailData();
    if (this.DataPassService.id == undefined) {

      this.router.navigateByUrl('pages/BankList');
    }
    else {

      this.buildItemForm(this.DataPassService)
    }

  }




  buildItemForm(item) {
    this.Id = item.id;
    this.Status = item.status;
    this.CancelChequeImage = item.cancel_cheque_img_path;
    this.PanImage = item.pan_img_path;
    this.GstImage = item.gst_img_path;


    this.itemForm = this.fb.group({
      name: [item.name || ''],
      bank_name: [item.bank_name || ''],
      account_number: [item.account_number || ''],
      ifsc: [item.ifsc || ''],
      branch: [item.branch || ''],
      city: [item.city || ''],
      cancel_cheque: [item.cancel_cheque || ''],
      pan: [item.pan || ''],
      gst: [item.gst || '']
    })
  }

  Cancelclick() {
    this.router.navigateByUrl('pages/BankList');
  }

  ApproveBankDetail(data: any = {}, isNew?) {
    this.confirmService.confirm({ message: `Are You Sure to Approve This Bank Detail?` })
      .subscribe(res => {
        if (res) {
          const objData: BankDetail = {} as BankDetail;
          objData.bank_details_id = data;
          objData.status="APPROVED";
          // alert(Id)
          this.TransService.ApproveBank(objData)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                this.router.navigateByUrl('pages/BankList');
                Swal.fire('Bank Detail Approved Successfully!');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            }, err => {
              Swal.fire(err.error.data.msg);
              console.log('error occured', err);
            });
        }
      })
  }


}
export interface BankDetail {
  bank_details_id:number;
  status: string;
  }