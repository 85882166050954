import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { SubCategoryDetails, CotrolData, InventoryMaster, ControlDetail,ElementData } from '../../../shared/models/models';
import { CustomValidators } from 'ng2-validation';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import Swal from 'sweetalert2';
import {RegistrationService} from 'app/shared/services/MyServices/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
  animations: egretAnimations,
})
export class UserRegistrationComponent implements OnInit {
  UserRegForm: FormGroup;
  constructor(private RegService:RegistrationService,private router:Router) { }

  ngOnInit() {
    this.UserRegForm = new FormGroup({
      party_type: new FormControl('', [Validators.required]),
      party_sub_type: new FormControl('', [Validators.required]),
      login_name: new FormControl('', [Validators.required]),
      distributor_code: new FormControl(''),
      mobile_no: new FormControl(''),
      email_id: new FormControl(''),
      position:new FormControl(''),
   //   password: new FormControl('', [Validators.required]),
    
    })
  }
  onSelectParty(){

  }
  onClear(){
    this.UserRegForm.reset;
  }
  btnsubmitForm(){

    this.RegService.AdminRegistration(this.UserRegForm.value).pipe().subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }

      if (res.success) {
       
      
        
        this.UserRegForm.reset();
        Swal.fire('Successfully saved!', 'success');
      //  this.router.navigate(['pages/AuctionList'])

      }
      else {
        Swal.fire(res.data.msg, 'Error')
      }

    },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });
  }
}
