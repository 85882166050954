import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { SubCategoryDetails, CotrolData, InventoryMaster, ControlDetail, InventoryList } from '../../../shared/models/models';
import { InventoryService } from '../../../shared/services/MyServices/inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ApproveData } from 'app/shared/models/models';




@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
  animations: egretAnimations
})
export class InventoryListComponent implements OnInit {

  public items: any[];
  temp = [];
  public getItemSub: Subscription;
  constructor(private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private InvenService: InventoryService,
  ) { }

  isPageUrlCheck: any
  ngOnInit() {
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'InvList') {
            this.isPageUrlCheck = "InvList"
          }
        }
      }
    }

    if (this.isPageUrlCheck == "InvList") {
      this.getItems()
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }

  }
  ngOnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe()
    }
  }
  getItems() {
    const InventoryListrData: InventoryList = {} as InventoryList;
    InventoryListrData.user_id = 1;
    InventoryListrData.offset = 0;
    this.getItemSub = this.InvenService.InventoryList(InventoryListrData)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }
        if (data.success = true) {
          this.items = this.temp = data.data as InventoryList[];
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }
      });
  }
  openPopUp(data: any = {}, isNew?) {
  }
  deleteItem(row) { }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    columns.splice(columns.length - 1);
    if (!columns.length)
      return;
    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  ApproveInventory(data: any = {}, isNew?) {
    this.confirmService.confirm({ message: `Approve ${data.inventory_code}?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          objData.id = data.inventory_id.toString();
          this.InvenService.ApproveInventory(objData)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                Swal.fire('Inventory Approved Successfully!');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })
  }
}
