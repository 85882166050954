


import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';
import { CommonService } from 'app/shared/services/MyServices/common.service';

@Component({
  selector: 'app-role-page-mapping',
  templateUrl: './role-page-mapping.component.html',
  styleUrls: ['./role-page-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RolePageMappingComponent implements OnInit {
  color;
  checked;
  CheckedLable: string;
  disabled;
  RoleTypeDisabled: boolean;
  isApprove: boolean;
  btnSave: boolean;
  btnUpdate: boolean;
  DstCOdeDisabled: boolean;
  rows = [];
  selected = [];
  isDisplayTable: boolean;
  DataPushArray = [];
  public itemForm: FormGroup;
  loginData: any;
  constructor(
    private data: DataPassService,
    private CommonService: CommonService,
    private snack: MatSnackBar,
    private router: Router,
    private loader: AppLoaderService,
    private fb: FormBuilder,
  ) { }
  public datas: any;
  RoleType: any[];
  Position: any[];

  click: Subscription;
  href: any;
  pagevalid: boolean
  Role: any
  isPageUrlCheck: any
  readable: any;
  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem("loginData"))
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'RolePageMapping') {
            this.isPageUrlCheck = "RolePageMapping";
            this.readable = entryDetail.readable
          }
        }
      }
    }

    if (this.isPageUrlCheck == "RolePageMapping") {
      var RoleName = this.CommonService.getRole();
      this.Role = RoleName;

      this.href = this.router.url;

      var splitted = this.href.split("/", 3);

      //this.pagevalid = this.data.GetPageVlidation(splitted[2])
      this.pagevalid = true;

      if (this.pagevalid == true) {

        this.selected = [];
        this.isDisplayTable = false
        this.GetPartyType();

        this.buildItemForm()

      }
      else {
        this.router.navigate(['pages/NOTFound']);
      }
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }







  }

  PartyTypeArray: any[];
  GetPartyType() {
    this.CommonService.BindPartyType('').subscribe(
      data => {
        if (data.success == true) {
          this.PartyTypeArray = data.data;
          this.PartyTypeArray.reverse();
          if(this.loginData.distributor_code){
            this.PartyTypeArray.splice(1, 2);
          }
        }
        else {
        }
      }, (err) => {
      });
  }

  SubPartyTypeArray: any[];
  GetSubPartyType(partytypeid) {
    var Json = {
      "party_type_id": partytypeid
    }

    this.CommonService.BindSubPartyType(Json).subscribe(
      data => {
        if (data.success == true) {
          this.SubPartyTypeArray = data.data;
        }
        else {
        }
      }, (err) => {
      }

    );




  }


  buildItemForm() {
    this.itemForm = this.fb.group({
      party_type: [''],
      party_sub_type: [''],



    })

    if (this.Role == "Distributor") {
      this.itemForm.get('role_id').setValue(2);
      this.GetSubPartyType(2)
      this.RoleTypeDisabled = true;


    }
  }

  OnselectedSubPartyType(Event) {
    this.bindList(Event);
  }

  bindList(event) {
    this.rows = [];
    this.selected = [];
    this.loader.open()
    console.log(this.itemForm.value);
    var input = this.itemForm.value;

    this.CommonService.GetPagemasterData(input).subscribe(

      data => {
        this.rows = data
        if (data.success == true) {
          this.loader.close()
          // console.log(data.data);
          // console.log(data);
          this.rows = data.data;

          for (let entry1 of this.rows) {

            if (entry1.page_status == true) {
              this.selected.push(entry1);
            }
          }

          //this.Position = data.data;

        }


        else {
          this.loader.close()

        }
      }, (err) => {
        this.loader.close()

      }

    );


    this.isDisplayTable = true
  }



  OnselectedParty(value) {
    this.GetSubPartyType(value);
  }

  submit() {
  }

  oncalcel() {
    ///this.router.navigate(['pages/RegistrationList']);
  }

  save() {
    this.DataPushArray = []
    //console.log(this.selected)
    this.loader.open()
    if (this.selected.length > 0) {


      for (let entry1 of this.selected) {
        const dataList1: FormArray = {} as FormArray;
        dataList1.page_detail_id = entry1.page_master_detail_id;
        dataList1.page_id = entry1.page_master_id;

        this.DataPushArray.push(dataList1);

      }


      const Final: FinalArray = {} as FinalArray;
      Final.party_type = this.itemForm.value.party_type;
      Final.party_sub_type = this.itemForm.value.party_sub_type;
      Final.page_list = this.DataPushArray;



      this.CommonService.Insertupdate_page_mapping(Final).subscribe(

        data => {
          //  console.log(data.rangeInfo.total_row);
          // this.page.totalElements=data.rangeInfo.total_row;
          this.loader.close();
          if (data.success == true) {



            //this.RefreshTab();
            // Swal.fire('Data Save Succesfully !');



            Swal.fire({
              title: 'Data Save Successfully !',
              // text: "You won't be able to revert this!",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.value) {
                //this.bindList(Event);
                this.rows = [];
                this.selected = [];
                this.itemForm.reset();
                this.GetPartyType();
                this.buildItemForm()
                this.isDisplayTable = false
              }
              else {
                //this.bindList(Event);
                this.rows = [];
                this.selected = [];
                this.itemForm.reset();
                this.GetPartyType();
                this.buildItemForm()
                this.isDisplayTable = false
              }
            })

          }



          else {


            this.loader.close();
          }
        }, (err) => {
          this.loader.close();

        }

      );


    }
    else {
      this.loader.close()
      Swal.fire('Please Select at Least One Page.');
    }


  }

  // onInvoicedSelect(row) {
  //   console.log(row)


  //   //  this.selected.push(row);


  //   // console.log(this.selected)
  // }



  update() {




  }

  CheckStatus(row) {
    console.log(row);
    return row;
  }


}


export class Updateinfo {
  action_type: string;
  account_pk: number;
  role_id: number;
  first_name: string;
  phone_number: string;
  email_id: string;
  last_name: string;
  position_id: number;
  distributor_code: string;
  is_active: boolean;





}





export interface FormArray {
  page_id: string;
  page_detail_id: string;

}


export interface FinalArray {
  role_id: string;
  position_id: string;
  party_type: string;
  party_sub_type: string;
  page_list: any[];

}