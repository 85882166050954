import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CommonService } from "app/shared/services/MyServices/common.service";
import { DataPassService } from "app/shared/services/MyServices/data-pass.service";
import { InventoryService } from "app/shared/services/MyServices/inventory.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-check-stock-vehicle",
  templateUrl: "./check-stock-vehicle.component.html",
  styleUrls: ["./check-stock-vehicle.component.scss"],
})
export class CheckStockVehicleComponent implements OnInit {
  @ViewChild("scrollBottom", { static: false })
  private scrollBottom: ElementRef;
  checkTML: any;
  DivisionId: string;
  public viewMode: string = "list-view";
  pgTitle = "Confirmation ";
  pgText = "Are you sure you want to change the selection?";
  ServiceData: [];
  visiblitydata = "private";
  ProductGroupLine = [];
  Type = [];
  config: any;
  StockVehicleInfo: any = [];
  InventoryImages: any = [];
  InventoryCoverImage: [];
  FinaldataData = [];
  temp = [];
  data: any;
  chassis_number = [];
  showmsg: boolean = false;

  p: number = 1;
  readable: any = false;

  constructor(
    private confirmService: AppConfirmService,
    private commonservice: CommonService,
    private loader: AppLoaderService,
    private router: Router,
    private Passdata: DataPassService,
  ) {
    this.config = {
      itemsPerPage: 0,
      currentPage: 0,
      totalItems: 0,
    };
  }

  isPageUrlCheck: any

  ngOnInit() {
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'StockVehcile') {
            this.isPageUrlCheck = "StockVehcile";
            this.readable = entryDetail.readable;
          }
        }
      }
    }
    

    if (this.isPageUrlCheck == "StockVehcile") {
      this.scrollToBottom();
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      this.DivisionId = localStorage.getItem("DivisionId");
      this.checkTML = "TML";
      var TMLdata = {
        app_name: "com.tatamotors.tataoksourcing",
        app_version: "1.2",
        dev_id: "897jhjh6",
        offset: 0,
        size: 1000,
        //brand_type: this.checkTML,
        brand_type: "all",
        applications: ["Market Load Operators"],
        budget_category: "0-* ",
        gvw_from: "all",
        gvw_to: "all",
        hsn_include: ["all"],
        model_vc_number: "all",
      };
      this.GetData(TMLdata);
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }
  }
  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop = 0;
    } catch (err) { }
  }
  changebrand(event) {
    this.checkTML = "";
    this.confirmService
      .LogOutOCnform({ title: this.pgTitle, message: this.pgText })
      .subscribe((result) => {
        var access = result;
        if (access == true) {
          if (event.value == "TML") {
            this.checkTML = "TML";
            var jsonn = {
              app_name: "com.tatamotors.tataoksourcing",
              app_version: "1.2",
              dev_id: "897jhjh6",
              offset: 0,
              size: 1000,
              brand_type: this.checkTML,
              applications: ["Market Load Operators"],
              budget_category: "0-* ",
              gvw_from: "all",
              gvw_to: "all",
              hsn_include: ["all"],
              model_vc_number: "all",
            };
            this.GetData(jsonn);
          }
          if (event.value == "NTML") {
            this.checkTML = "NTML";
            var jsonn1 = {
              app_name: "com.tatamotors.tataoksourcing",
              app_version: "1.2",
              dev_id: "897jhjh6",
              offset: 0,
              size: 1000,
              brand_type: this.checkTML,
              applications: ["Market Load Operators"],
              budget_category: "0-* ",
              gvw_from: "all",
              gvw_to: "all",
              hsn_include: ["all"],
              model_vc_number: "all",
            };
            this.GetData(jsonn1);
          }
        } else {
          this.checkTML = "";
          if (event.value == "TML") {
            this.checkTML = "NTML";
          }
          if (event.value == "NTML") {
            this.checkTML = "TML";
          }
        }
      });
  }
  getchassis = [];
  GetData(input) {
    // window.scrollTo(0, 0);
    this.showmsg = false;
    this.FinaldataData = [];
    setTimeout(() => {
      this.loader.open();
    });
    let chassis_numberSet = [];
    this.commonservice.BindStockVehicle(input).subscribe((data) => {
      let total_vehicles_count = data.total_results;
      if (total_vehicles_count > 0) {
        this.showmsg = true;
        this.config.itemsPerPage = 10;
        // this.config.offset = data.rangeInfo.offset;
        this.config.totalItems = data.total_results;
        this.StockVehicleInfo = data.result;
        let color, model_name, mfg_date, model_id, brand_type, ppl, ppl_id, registration_number, chassis_number, msrp, lob, lis_cover, Vehicle_cat, hsn, grows_weight, vc_number: string;
        for (let stockVehicle of this.StockVehicleInfo) {
          this.InventoryImages = [];
          this.InventoryCoverImage = [];
          color = stockVehicle.color;
          model_id = stockVehicle.model_id;
          model_name = stockVehicle.model_name;
          brand_type = stockVehicle.brand_type;
          ppl = stockVehicle.ppl;
          ppl_id = stockVehicle.ppl_id;
          registration_number = stockVehicle.registration_number;
          chassis_number = stockVehicle.chassis_number;
          chassis_numberSet.push(stockVehicle.chassis_number);
          vc_number = stockVehicle.vc_number;
          msrp = stockVehicle.msrp;
          lob = stockVehicle.lob;
          mfg_date = stockVehicle.mfg_date;
          Vehicle_cat = stockVehicle.Vehicle_cat;
          hsn = stockVehicle.hsn;
          grows_weight = stockVehicle.grows_weight;

          this.FinaldataData.push(
            this.formData(color, model_name, mfg_date, model_id, brand_type, ppl, ppl_id, registration_number, chassis_number, msrp,
              lob, this.InventoryCoverImage, Vehicle_cat, hsn, grows_weight, vc_number));
          this.temp = this.FinaldataData;
          window.scrollTo(0, 0);
          this.loader.close();
        }

        this.checkduplicate(chassis_numberSet);
      } else {
        this.showmsg = true;
        Swal.fire({
          icon: 'error',
          text: data.msg
        })
        this.loader.close();
      }
    },
      (error) => {
        //this.showmsg=true;
        this.loader.close();
        Swal.fire({
          icon: 'error',
          text: error.error.msg
        })
      }
    );
  }

  formData(color, model_name, mfg_date, model_id, brand_type, ppl, ppl_id, registration_number,
    chassis_number, msrp, lob, is_cover, Vehicle_cat, hsn, grows_weight, vc_number): InventoryListData {
    const InventoryListData: InventoryListData = {} as InventoryListData;
    InventoryListData.color = color;
    InventoryListData.model_name = model_name;
    InventoryListData.mfg_date = mfg_date;
    InventoryListData.model_id = model_id;
    InventoryListData.brand_type = brand_type;
    InventoryListData.ppl = ppl;
    InventoryListData.ppl_id = ppl_id;
    InventoryListData.registration_number = registration_number;
    InventoryListData.chassis_number = chassis_number;
    InventoryListData.msrp = msrp;
    InventoryListData.lob = lob;
    InventoryListData.is_cover = is_cover;
    InventoryListData.Vehicle_cat = Vehicle_cat;
    InventoryListData.hsn = hsn;
    InventoryListData.grows_weight = grows_weight;
    InventoryListData.vc_number = vc_number;
    return InventoryListData;
  }
  // formData1(chassis_number): InventoryListData {
  //   const InventoryListData: InventoryListData = {} as InventoryListData;
  //   InventoryListData.chassis_number = chassis_number;
  //   return InventoryListData;
  // }
  AddVehicle(data: any = {}) {
    localStorage.removeItem("vc_number");
    localStorage.setItem("vc_number", data.vc_number);
    localStorage.removeItem("model_id");
    localStorage.setItem("model_id", data.model_id);
    this.Passdata.setStockData(data);
    this.router.navigate(["pages/InventoryMaster/"]);
  }

  // pageChanged(event) {
  //   var offset=event;
  //   if(offset>0)
  //   {
  //     offset=offset-1;
  //   }
  //   this.config.currentPage = event;
  //   this.Getdata(offset);
  // }
  Checkchassis_number: [];
  hidebutton: boolean = false;
  label: string;
  tempdata = [];
  tempdata1 = []
  checkduplicate(chassis_n) {
    this.commonservice.CheckDuplicateChassis({ 'chassis_no_s': chassis_n }).subscribe(
      (res) => {
        if (res instanceof HttpErrorResponse) {
          return;
        }
        if (res.success) {
          let chassisSet = res.data;
          for (let i = 0; i < this.FinaldataData.length; i++) {
            this.FinaldataData[i]["chassis_number"] = chassisSet[i];
          }
          this.FinalData(this.FinaldataData)

        } else {
          const errorMessage = res;
          return;
        }
      },
      (err) => {

        // Swal.fire(
        //   "You cannot add this vehicle, this chassis number is already used"
        // );
        return;
        console.log("error occured", err);
      }
    );
  }

  FinalData(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].chassis_number.is_exists == false) {
        this.tempdata.push(data[i])
      }
    }
  }

  off: number;
  pageChanged(event) {
    document.body.scrollTop = 0;
    var offset = event;
    this.off = event;
    if (offset > 0) {
      offset = offset - 1;
    }
    this.config.currentPage = event;

    var TMLdata = {
      app_name: "com.tatamotors.tataoksourcing",
      app_version: "1.2",
      dev_id: "897jhjh6",
      offset: offset,
      size: 1000,
      //brand_type: this.checkTML,
      brand_type: "all",
      applications: ["Market Load Operators"],
      budget_category: "0-* ",
      gvw_from: "all",
      gvw_to: "all",
      hsn_include: ["all"],
      model_vc_number: "all",
    };
    this.GetData(TMLdata);
  }

}

export class InventoryListData {
  is_cover: string;
  color: string;
  model_name: string;
  model_id: string;
  brand_type: string;
  ppl: string;
  ppl_id: string;
  msrp: string;
  lob: string;
  vc_number: string;
  registration_number: string;
  chassis_number: string;
  mfg_date: Date;
  Vehicle_cat: string;
  hsn: string;
  grows_weight: string
}
