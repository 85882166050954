import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { RegistrationService } from '../../../shared/services/MyServices/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserRegistration } from 'app/shared/models/user-registration';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';
import { Page } from '../../../../../src/app/shared/models/PaginationPage';
@Component({
  selector: 'app-admin-registration-list',
  templateUrl: './admin-registration-list.component.html',
  styleUrls: ['./admin-registration-list.component.scss'],
  animations: egretAnimations
})
export class AdminRegistrationListComponent implements OnInit {
  page = new Page();
  BuyerListData: UserRegistration[];
  public items: any[];
  temp = [];
  public getItemSub: Subscription;
  readable: any = false;
  page_count: any;
  constructor(private router: Router,
    private RegService: RegistrationService,
    private DataPassServic: DataPassService,
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.totalElements = 0;
  }

  isPageUrlCheck: any;
  ngOnInit() {
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'AdminUserList') {
            this.isPageUrlCheck = "AdminUserList";
            this.readable = entryDetail.readable
          }
        }
      }
    }

    if (this.isPageUrlCheck == "AdminUserList") {
      this.getTMLuserList(0)
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }

  }

  getTMLuserList(Offset) {
    const ObjData: UserList = {} as UserList;
    ObjData.offset = Offset;

    this.getItemSub = this.RegService.GetSellerRegistrationList(ObjData)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }
        if (data.success = true) {
          this.page_count = data.page_count;
          this.page.totalElements = data.page_count;
          this.items = [...data.data];
          this.temp = [...data.data];
          //  console.log(this.items);
          //this.items =this.temp = data;
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }
      });


  }

  openPopUp(data: any = {}, isNew?) {
    //alert('Hi');
    //console.log(data);
    // localStorage.setItem('UserData',data);
    this.DataPassServic.setUserData(data);
    this.router.navigateByUrl('pages/AdminReg');
  }

  deleteItem(row) {

  }

  updateFilter(event,value) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    // columns.splice(columns.length - 1);
    columns.splice(columns.length);
    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
    if(value){
      // console.log(value);
      this.page.totalElements = this.items.length;
    }else{
      this.page.totalElements = this.page_count;
    }
  }
  setPage(pageInfo) {
    var offset = pageInfo.offset;
    this.getTMLuserList(offset)
  }
}

export interface UserList {
  offset: number;
}