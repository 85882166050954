import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import { DataPassService } from "app/shared/services/MyServices/data-pass.service";
import { CommonService } from "app/shared/services/MyServices/common.service";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: "app-position-master",
  templateUrl: "./position-master.component.html",
  styleUrls: ["./position-master.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PositionMasterComponent implements OnInit {
  color;
  checked;
  CheckedLable: string;
  disabled;
  RoleTypeDisabled: boolean;
  isApprove: boolean;
  btnSave: boolean;
  btnupdate: boolean;
  btnUpdate: boolean;
  DstCOdeDisabled: boolean;
  rows = [];
  selected = [];
  RoleType: any[];
  Position: any[];
  click: Subscription;

  href: any;
  pagevalid: boolean;
  Role: any;
  @ViewChild("input1", { static: false }) inputEl: ElementRef;
  isDisplayTable: boolean;
  public itemForm: FormGroup;
  party_sub_type_id: any;
  readable: any;
  constructor(
    private data: DataPassService,
    private CommonService: CommonService,
    private snack: MatSnackBar,
    private router: Router,
    private fb: FormBuilder,) { }
  public datas: any;
  isPageUrlCheck: any;
  ngOnInit() {

    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'PartyMaster') {
            this.isPageUrlCheck = "PartyMaster"
            this.readable = entryDetail.readable
          }
        }
      }
    }

    if (this.isPageUrlCheck == "PartyMaster") {
      var RoleName = this.CommonService.getRole();
      this.Role = RoleName;
      this.href = this.router.url;
      var splitted = this.href.split("/", 3);
      // this.pagevalid = this.data.GetPageVlidation(splitted[2])
      this.pagevalid = true;
      if (this.pagevalid == true) {
        this.RoleTypeDisabled = false;
        this.selected = [];
        this.isDisplayTable = false;
        // this.rows = json;
        this.btnSave = true;
        this.btnupdate = false;
        // this.datas = this.data.getOption();
        this.GetPartyType();
        this.buildItemForm("");
      } else {
        this.router.navigate(["pages/NOTFound"]);
      }
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }



  }

  omit_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  PartyTypeArray: any[];
  GetPartyType() {
    this.CommonService.BindPartyType("").subscribe(
      (data) => {
        if (data.success == true) {
          this.PartyTypeArray = data.data;
          let loginData = JSON.parse(localStorage.getItem("loginData"))
          this.PartyTypeArray.reverse();
          if (loginData.distributor_code) {
            this.PartyTypeArray.splice(1, 2);
          }
        } else {
        }
      },
      (err) => { }
    );
  }

  GetPosition(id) {
    var Json = { role_id: id };
    this.CommonService.BindPosition(Json).subscribe(
      (data) => {
        if (data.success == true) {
          this.Position = data.data;
        } else {
        }
      },
      (err) => { }
    );
  }

  buildItemForm(row) {
    // var X = "true";
    // this.checked = false;
    // this.CheckedLable = "Inactive";

    this.itemForm = this.fb.group({
      party_type: [row.party_type_id || "", Validators.required],
      party_sub_type_name: [row.party_sub_type_name || "", Validators.required],
      // position_id: [row.position_id || ''],
    });
    if (this.Role == "Distributor") {
      this.itemForm.get("role_id").setValue(2);
      this.GetPosition(2);
      this.RoleTypeDisabled = true;
      var input = this.itemForm.value;
      this.GetList(input);
    }
  }

  OnselectedParty(value) {
    this.btnSave = true;
    this.btnupdate = false;
    this.rows = [];
    var input = this.itemForm.value;
    this.GetList(input);
  }
  GetList(input) {
    this.rows = [];
    this.CommonService.GetSubpartyMappingList(input).subscribe(
      (data) => {
        this.rows = data;
        if (data.success == true) {
          this.rows = data.data;
          console.log(data);
        } else {
        }
      },
      (err) => { }
    );
  }

  submit() { }

  oncalcel() {
    ///this.router.navigate(['pages/RegistrationList']);
  }

  save() {
    if (this.itemForm.invalid) {
      return;
    }
    console.log(this.selected);
    const Final: Updateinfo = {} as Updateinfo;
    Final.party_type = this.itemForm.value.party_type;
    Final.action_type = "create";
    Final.party_sub_type_name = this.itemForm.value.party_sub_type_name;
    // Final.status = "insert";
    this.insertupdatePosition(Final);
  }
  Reset() {
    this.itemForm.reset();
    this.btnSave = true;
    this.btnupdate = false;
    this.RoleTypeDisabled = false;
    this.rows = [];
    this.GetPartyType();
    //this.router.navigate(['pages/Positionmaster']);
  }

  Edit(row) {
    debugger
    setTimeout(() => this.inputEl.nativeElement.focus());
    this.RoleTypeDisabled = true;
    this.btnSave = false;
    this.btnupdate = true;
    this.buildItemForm(row);
    this.party_sub_type_id = row.party_sub_type_id
  }

  update() {
    if (this.itemForm.invalid) {
      return;
    }
    console.log(this.itemForm.value);
    const Final: Updateinfo = {} as Updateinfo;
    Final.party_type = this.itemForm.value.party_type;
    Final.action_type = "update";
    Final.party_sub_type_name = this.itemForm.value.party_sub_type_name;
    Final.party_sub_type_id = this.party_sub_type_id;
    // Final.status = "update";
    this.insertupdatePosition(Final);
  }

  insertupdatePosition(Final) {
    debugger
    this.CommonService.InsertUpdateSubpartyMapping(Final).subscribe(response => {
      // if (response instanceof HttpErrorResponse) {
      //   return
      // }
      debugger
      if (response.success) {
        //Swal.fire(response.data.msg)
        debugger
        Swal.fire({
          icon: "success",
          title: response.data.msg,
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          debugger
          if (result.value) {
            this.itemForm.reset();
            this.btnSave = true;
            this.btnupdate = false;
            this.RoleTypeDisabled = false;
            this.rows = [];
            this.GetPartyType();
            if (this.Role == "Distributor") {
              this.buildItemForm("");
            }
          }
          else {
            debugger
            this.itemForm.reset();
            this.itemForm.reset();
            this.btnSave = true;
            this.btnupdate = false;
            this.RoleTypeDisabled = false;
            this.rows = [];
            this.GetPartyType();
            if (this.Role == "Distributor") {
              this.buildItemForm("");
            }
          }
        });
      }
      else {
        debugger;
        Swal.fire({
          icon: 'info',
          title: response.error.data.msg
        });
      }
    });
  }

  CheckStatus(row) {
    console.log(row);
    return row;
  }
}

export class Updateinfo {
  status: string;
  party_sub_type_name: string;
  party_type: string;
  action_type: string;
  party_sub_type_id: string;
}
