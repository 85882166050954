import { Component, OnInit, Input, Renderer2, EventEmitter, Output } from "@angular/core";
import { LayoutService } from "app/shared/services/layout.service";
import { CustomizerService } from "app/shared/services/customizer.service";
import { ThemeService, ITheme } from "app/shared/services/theme.service";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { DatePipe } from "@angular/common";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ViewEncapsulation } from "@angular/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "app/shared/directives/date.adapter";
import { CommonService } from "app/shared/services/MyServices/common.service";
import { NavigationService } from "app/shared/services/navigation.service";
import { Subject, ReplaySubject, Observable } from "rxjs";
import { TataServiceService } from "app/TataService/tata-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { first } from 'rxjs/operators';
import { HostListener } from "@angular/core";
import { FormErrorService } from "app/shared/services/form-error/form-error.service";
import { RegexService } from "app/shared/services/regex/regex.service";
@Component({
  selector: 'app-view-vehicle-filter',
  templateUrl: './view-vehicle-filter.component.html',
  styleUrls: ['./view-vehicle-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class ViewVehicleFilterComponent implements OnInit {

  myControl = new FormControl();
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  public itemForm: FormGroup;
  isCustomizerOpen: boolean = false;
  viewMode: 'options' | 'json' = 'options';
  DistributorData = []
  Division = [];
  filterValue: any;
  filterValue1: any;
  filterValue2: any;
  filterValue3: any;
  StateArray: any[];
  DistictArray: any[];
  CityArray: any[];
  TalukaArray: any[];
  PincodeArray: any[];
  stateCode: string;
  stateName: string;
  districtName: string;
  cityName: string;
  talukaName: any[];
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  sidebarColors: any[];
  topbarColors: any[];
  RoleType: any[];
  RoleName: any;
  DistCode: any;
  isDistDrpDownVisible: boolean;
  Position: any[];
  layoutConf;
  selectedMenu: string = "icon-menu";
  selectedLayout: string;
  isTopbarFixed = false;
  isFooterFixed = false;
  isRTL = false;
  egretThemes: ITheme[];
  perfectScrollbarEnabled: boolean = true;
  public bankFilterCtrl: FormControl = new FormControl();

  constructor(
    private datepipe: DatePipe,
    private CommonService: CommonService,
    public layout: LayoutService,
    private regexService: RegexService,
    private formErrorService: FormErrorService,
    private themeService: ThemeService,
    private TataService: TataServiceService,
    public customizer: CustomizerService,
    private renderer: Renderer2,
    private fb: FormBuilder) { }
  @Input() placeholderLabel = 'Suche111';
  @Output() messageEvent = new EventEmitter<string>();
  private _onDestroy = new Subject<void>();

  onClick() {
    this.isCustomizerOpen = false
  }
  ngOnInit() {
    this.BindState();
    this.BindSourceOfContact();
    this.BindLOB();
    this.filterValue = null;
    const data1: InputData2 = {} as InputData2;
    data1.account_name = "";
    this.isDistDrpDownVisible = true;
    if (this.RoleName != "TML") {
      this.isDistDrpDownVisible = false;
      const data: InputData1 = {} as InputData1;
      data.distributor_id = this.DistCode;
      data.div_search_text = "";
    }

    const data: InputData = {} as InputData;
    data.size = 10;
    data.org_search_text = "";
    this.layoutConf = this.layout.layoutConf;
    this.selectedLayout = this.layoutConf.navigationPos;
    this.isTopbarFixed = this.layoutConf.topbarFixed;
    this.isRTL = this.layoutConf.dir === "rtl";
    this.egretThemes = this.themeService.egretThemes;
    this.BuildForm();
  }

  BuildForm() {
    this.itemForm = this.fb.group({
      lead_id: [],
      opty_id: [],
      full_name: ['', [Validators.pattern(this.regexService.HumanName)]],
      from_date: [],
      to_date: [],
      // from_date: [new Date(localStorage.getItem("FromDate"))],
      // to_date: [new Date(localStorage.getItem("ToDate"))],
      customer_type: [],
      organization_name: [],
      distributor_code: [],
      mobile_no: ['', [Validators.pattern(this.regexService.Phone)]],
      vc_number: [],
      chassis_number: [],
      lob: [],
      state: [],
      district: [],
      city: [],
      vehicle_application: [],
      source_of_contact: [],

    })
  }
  DivisionName: any
  filterMyOptions1(Event, name, event) {
    if (event.isUserInput) {
      this.filterValue2 = Event;
      this.DivisionName = name;
    }
    else {
      this.filterValue2 = "";
      this.DivisionName = "";
    }
  }

  private _filter(value: string): string[] {
    this.filterValue1 = value;
    if (this.filterValue1 == "") {
      this.filterValue = null;
    }
    const data: InputData = {} as InputData;
    data.size = 10;
    data.org_search_text = this.filterValue1;
    return this.options.filter(option => option.toLowerCase().includes(this.filterValue1));
  }
  private _filter1(value: string): string[] {
    this.filterValue3 = value;
    if (this.filterValue3 == "") {
      this.filterValue2 = null;
    }
    return this.options.filter(option => option.toLowerCase().includes(this.filterValue3));
  }

  Custname: any
  Custid: any
  filterMyOptionsCustname(Event, name, event) {
    if (event.isUserInput) {
      this.Custname = name
      this.Custid = Event;
    }
    else {
      this.Custname = ""
      this.Custid = "";
    }
  }


  DistibutoName: any
  filterMyOptions(Event, name, event) {
    if (event.isUserInput) {
      this.DistibutoName = name;
      this.myControl1.setValue('');
      this.filterValue = Event;
      const data: InputData1 = {} as InputData1;
      data.distributor_id = Event;
      data.div_search_text = "";
    }
    else {
      this.DistibutoName = "";
      this.filterValue = "";
    }

  }

  onChange(event: any): void {
    this.tableOffset = event.offset;
  }


  tableOffset: any;
  reset() {
    this.tableOffset = 0;
    this.itemForm.reset();
    this.BuildForm();
    this.myControl.reset();
    this.myControl1.reset();
    this.messageEvent.emit(this.itemForm.value)
    this.filterValue = null;
    this.filterValue2 = null;
    this.Custid = ""
    this.Custname = ""
    this.myControl2.reset();
    this.isCustomizerOpen = false;

  }

  calculateDate(date1, date2) {
    //our custom function with two parameters, each for a selected date
    date1 = new Date(date1);
    date2 = new Date(date2);
    var diffc = date1.getTime() - date2.getTime();
    //getTime() function used to convert a date into milliseconds. This is needed in order to perform calculations.

    var days = Math.round(Math.abs(diffc / (1000 * 60 * 60 * 24)));
    //this is the actual equation that calculates the number of days.
    return days;
  }





  Search() {
    if (this.itemForm.value.from_date !== null || this.itemForm.value.to_date !== null) {

      if (this.itemForm.value.to_date == null && this.itemForm.value.to_date !== null) {
        Swal.fire('Select From Date');
      }
      else if (this.itemForm.value.to_date !== null && this.itemForm.value.to_date == null) {
        Swal.fire('Select To Date');
      }
      if (this.itemForm.value.from_date !== null && this.itemForm.value.to_date !== null) {
        // var d1 = Date.parse(this.itemForm.value.from_date);
        // var d2 = Date.parse(this.itemForm.value.to_date);
        var d1 = Date.parse(this.datepipe.transform(this.itemForm.value.from_date, 'yyyy-MM-dd'));
        var d2 = Date.parse(this.datepipe.transform(this.itemForm.value.to_date, 'yyyy-MM-dd'));
        if (d1 > d2) {
          Swal.fire('From-Date Should be Less Than To-Date.');
        }
        else {
          //  var days = this.calculateDate(this.itemForm.value.from_date, this.itemForm.value.to_date);
          var days = this.calculateDate(this.datepipe.transform(this.itemForm.value.from_date, 'MM-dd-yyyy'), this.datepipe.transform(this.itemForm.value.to_date, 'MM-dd-yyyy'));
          if (days == 90 || days <= 90) {
            this.messageEvent.emit(this.itemForm.value);
            this.tableOffset = 0;
            this.isCustomizerOpen = false;
          }
          else {
            Swal.fire(' Allow to get Only 90 Days Data');
          }
        }
      }
    }
    else {
      this.messageEvent.emit(this.itemForm.value)
      this.tableOffset = 0;
      this.isCustomizerOpen = false;
    }

  }




  BindState() {
    this.TataService.BindState('').pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.StateArray = res;
        this.DistictArray = [];
        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      })
  }


  onSelectState(value) {
    this.stateCode = value.code;
    this.stateName = value.name;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"}}';
    this.TataService.BindDistrict(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.DistictArray = res;
        if (this.districtName != undefined) {
          this.onSelectDistrict(this.districtName);
        }
        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }

  onSelectDistrict(value) {
    this.districtName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '"}';
    this.TataService.BindCity(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.CityArray = res;
        if (this.cityName != undefined) {
          this.onSelectCity(this.cityName);
        }
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  onSelectCity(value) {
    this.cityName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '"}';
    this.TataService.BindTaluka(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.TalukaArray = res;
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  ///BindSourceOfContact
  SourceContact: any;
  BindSourceOfContact() {
    this.CommonService.BindSourceOfContact().pipe().subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.success) {
        this.SourceContact = res.data;
      }
      else {
        const errorMessage = res;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      })
  }


  categoryArray: any;
  BindVCApplication: any;
  BindLOB() {
    this.CommonService.BindCategory({ 'cat_id': 2 }).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.success) {
        this.categoryArray = res.data;
      }
      else {
        const errorMessage = res;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      })
  }

  Lobname: any
  BindVehicleApplication(value) {
    this.Lobname = value.sub_cat_name;
    var json = { 'lob_name': this.Lobname }
    this.CommonService.BindVehicleApplication(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.success) {
        this.BindVCApplication = res.data;
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  public hasRequiredError = (controlName: string): boolean =>
  this.formErrorService.hasRequiredError(controlName, this.itemForm);
  public hasPatternError = (controlName: string): boolean =>
  this.formErrorService.hasPatternError(controlName, this.itemForm);
  public hasMinLengthError = (controlName: string): boolean =>
  this.formErrorService.hasPatternError(controlName, this.itemForm);


}



interface Bank {
  id: string;
  name: string;
}

export class InputData {
  size: number
  org_search_text: string
}

export class InputData1 {
  distributor_id: string
  div_search_text: string
}


export class OutPutValue {
  month: string
  year: string
  div_id: string
  invoice_status: string
  distributor_id: string
}

export class InputData2 {
  account_name: string
}






