import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import Swal from 'sweetalert2';
import { RegistrationService } from 'app/shared/services/MyServices/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { MustMatch } from "app/shared/helpers/must-match.validator"
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { ApproveData } from 'app/shared/models/models';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';



@Component({
  selector: 'app-admin-registration',
  templateUrl: './admin-registration.component.html',
  styleUrls: ['./admin-registration.component.scss'],
  animations: egretAnimations,
})
export class AdminRegistrationComponent implements OnInit {

  PartyTypeArray: any[];
  SubPartyTypeArray: any[];
  RoleTypeArray: any[];
  public DataUserPassService: any;
  isApprove: boolean;
  isASubmit: boolean;
  StatusCheck: boolean;
  AdminRegForm: FormGroup;
  isReadOnly: boolean;
  bookFilteredList: any;
  readable: any;
  loginData: any;
  SubPartyTypeRespArray: any = [];
  constructor(private formBuilder: FormBuilder, private confirmService: AppConfirmService,
    private commonService: CommonService, private RegService: RegistrationService, private router: Router
    , private DataPassServic: DataPassService, private loader: AppLoaderService
  ) { }

  isPageUrlCheck: any
  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem("loginData"))
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'AdminReg') {
            this.isPageUrlCheck = "AdminReg"
            this.readable = entryDetail.readable
          }
        }
      }
    }

    if (this.isPageUrlCheck == "AdminReg") {
      this.BindPartyType();
      this.BindRoleType();

      this.DataUserPassService = this.DataPassServic.getUserData();

      if (this.DataUserPassService.pk_userid == null || this.DataUserPassService.pk_userid == '') {
        this.isASubmit = false;
        this.isApprove = true;
        //  alert('Add');
        this.buildItemForm('');

      }
      else {
        this.isASubmit = true;
        this.isApprove = false;

        if (this.DataUserPassService.status == 'inactive' || this.DataUserPassService.status == 'Inactive') {

          this.StatusCheck = false
        }
        else {
          this.StatusCheck = true
        }

        this.buildItemForm(this.DataUserPassService);

        // console.log('this.AdminRegForm');
        // console.log(this.AdminRegForm.value);
        // console.log(this.DataUserPassService);
        this.onSelectParty(this.DataUserPassService.party_type_id);

      }

      this.DataUserPassService = this.DataPassServic.resetOption();
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }


    // this.AdminRegForm = new FormGroup({
    // this.AdminRegForm = this.formBuilder.group({
    //   party_type: new FormControl('', [Validators.required]),
    //   party_sub_type: new FormControl('', [Validators.required]),
    //   login_name: new FormControl('', [Validators.required]),
    //   distributor_code: new FormControl(''),
    //   mobile_no: new FormControl(''),
    //   //email_id: new FormControl(''),
    //   position: new FormControl(''),
    //   password: "12365",

    // });

    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.AdminRegForm.patchValue({
      distributor_code: loginData.distributor_code
    });
  }

  buildItemForm(item) {
    // this.Id = item.deposit_details_id;
    // this.Status = item.status;


    // console.log("item ===== ",item);

    this.AdminRegForm = this.formBuilder.group({
      party_type_id: [item.party_type_id || null, Validators.required],
      party_sub_type_id: [item.party_sub_type_id || null, Validators.required],
      login_name: [item.login_name || null, Validators.required],
      distributor_code: [item.distributor_code || null, Validators.required],
      mobile_no: [item.mobile_no || null, Validators.required],
      //email_id: new FormControl(''),
      role_id: [Number(item.position) || null, Validators.required],
      last_name: [item.last_name || null],
      first_name: [item.first_name || null],
      password: ["" || null],
      user_code: [item.user_code || null],
      status: [item.status || null],
    })
    if (this.loginData.distributor_code != '') {
      this.AdminRegForm.controls['party_type_id'].setValue(1);
      this.AdminRegForm.controls['role_id'].setValue(2);
      this.onSelectParty(1)
    }


  }

  BindPartyType() {
    this.commonService.BindPartyType('').subscribe(
      data => {
        // if (data.success == true) {
        this.PartyTypeArray = data.data;

        // console.log( this.PartyTypeArray);
        // console.log(this.PartyTypeArray);

        this.PartyTypeArray.reverse();
        if (this.loginData.distributor_code) {
          this.PartyTypeArray.splice(1, 2);
        }

        // this.PartyTypeArray.multisplice(3, 5, 9, 1);
        // console.log(this.PartyTypeArray);
        // this.AdminRegForm.controls['party_type_id'].setValue('1');

        // this.PartyTypeArray = data.data;
        // }
        // else {
        //   Swal.fire('Oops...', data.msg, 'error')
        // }
      }, (err) => {
        Swal.fire('Oops...', err.msg, 'error')
      }
    );
  }


  Approve() {

    //alert(this.AdminRegForm.value.user_code);



    this.confirmService.confirm({ message: `Are You Sure to Active  this User?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          // objData.id = data.toString();
          // alert(Id)
          let json = '{"user_code":"' + this.AdminRegForm.value.user_code + '", "status":"Active"}';
          this.RegService.ADminUserApprove(json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                this.router.navigateByUrl('pages/AdminUserList');
                Swal.fire('User Activation Successfully!');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })



  }



  Reject() {

    //alert(this.AdminRegForm.value.user_code);



    this.confirmService.confirm({ message: `Are You Sure to Reject  this User?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          // objData.id = data.toString();
          // alert(Id)
          let json = '{"user_code":"' + this.AdminRegForm.value.user_code + '", "status":"Inactive"}';
          this.RegService.ADminUserApprove(json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                this.router.navigateByUrl('pages/AdminUserList');
                Swal.fire('User Reject Successfully!');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })



  }




  BindRoleType() {
    this.commonService.BindRoleType('').subscribe(
      data => {
        // if (data.success == true) {
        this.RoleTypeArray = data.data;

        this.RoleTypeArray.reverse();
        if (this.loginData.distributor_code) {
          this.RoleTypeArray.splice(1, 2);
        }
        // }
        // else {
        //   Swal.fire('Oops...', data.msg, 'error')
        // }
      }, (err) => {
        Swal.fire('Oops...', err.msg, 'error')
      }
    );
  }

  onSelectParty(value) {
    this.SubPartyTypeRespArray = []
    this.SubPartyTypeArray = [];
    // alert(value);
    // const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    // dataList.cat_id = value.cat_id;

    if (value == 4) {
      this.isReadOnly = true;

    }
    else {
      this.isReadOnly = false;

    }

    let json = '{"party_type_id":"' + value + '"}';
    this.commonService.BindSubPartyType(json).subscribe(
      data => {
        // if (data.success == true) {
        this.SubPartyTypeRespArray = [...data.data];
        this.SubPartyTypeArray = [...data.data];
        // }
        // else {
        //   Swal.fire('Oops...', data.msg, 'error')
        // }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      }
    );
  }


  // BindPartySubType() {
  //   this.commonService.BindSubPartyType().subscribe(
  //     data => {
  //       if (data.success == true) {
  //         this.SubPartyTypeArray = data.data;
  //       }
  //       else {
  //         Swal.fire('Oops...', data.msg, 'error')
  //       }
  //     }, (err) => {
  //       Swal.fire('Oops...', err.msg, 'error')
  //     }
  //   );
  // }

  btnsubmitForm() {

    // let json = '{"mobile_no":"' + this.AdminRegForm.value.mobile_no + '","distributor_code":"' + this.AdminRegForm.value.distributor_code + '","position":"' + this.AdminRegForm.value.role_id + '","login_name":"' + this.AdminRegForm.value.login_name + '","party_sub_type_id":"' + this.AdminRegForm.value.party_sub_type_id + '","party_type_id":"' + this.AdminRegForm.value.party_type_id + '","first_name":"' + this.AdminRegForm.value.first_name + '","last_name":"' + this.AdminRegForm.value.last_name + '"}';
    if(this.AdminRegForm.invalid){
      Swal.fire("Please add all mandatory details.")
      return;
    }
    let json = {
      "mobile_no": this.AdminRegForm.value.mobile_no,
      "distributor_code": this.AdminRegForm.value.distributor_code,
      "position": this.AdminRegForm.value.role_id,
      "login_name": this.AdminRegForm.value.login_name,
      "party_sub_type_id": this.AdminRegForm.value.party_sub_type_id,
      "party_type_id": this.AdminRegForm.value.party_type_id,
      "first_name": this.AdminRegForm.value.first_name,
      "last_name": this.AdminRegForm.value.last_name
    }
    // this.RegService.AdminRegistration(this.AdminRegForm.value).pipe().subscribe(res => {
    this.RegService.AdminRegistration(json).pipe().subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }

      if (res.success) {

        this.AdminRegForm.reset();
        Swal.fire('Successfully saved!');
        this.router.navigate(['pages/AdminUserList'])

      }
      else {
        Swal.fire(res.data.msg, 'Error')
      }

    },
      error => {
        Swal.fire(error.error.data.msg, "Error");
      });
  }
  onClear() {
    this.isASubmit = false;
    this.isApprove = true;
    this.AdminRegForm.reset();
    this.AdminRegForm.controls["distributor_code"].setValue(this.loginData.distributor_code)
    // this.AdminRegForm.patchValue({
    //   distributor_code: this.loginData.distributor_code
    // });
  }

  //   onkeypress(value:any){
  // let data={
  //   'user_name':value
  // }
  // this.commonService.getUserCode(data)

  //   }

  onkeypress(value: any) {
    if (!this.AdminRegForm.controls['login_name'].value) {
      return;
    }
    let data = {
      'user_name': this.AdminRegForm.controls['login_name'].value
    }
    this.loader.open();
    this.commonService.ValidatUserName(data).subscribe(
      data => {
        if (data.success == true) {
          //   {
          //     "success": true,
          //     "position": [
          //         "DGM",
          //         "DAM"
          //     ],
          //     "Delaer code": ""
          // }
          if (data.data) {
            this.SubPartyTypeArray = [];

            this.SubPartyTypeRespArray.forEach((e) => {
              if (data.data.includes(e.party_type_name)) {
                this.SubPartyTypeArray.push(e)
              }
            })
            console.log("this.SubPartyTypeArray  === ", this.SubPartyTypeArray);

            if (this.SubPartyTypeArray.length == 0) {
              Swal.fire("Positions for this user not exist.")
            }
            // console.log(this.SubPartyTypeArray);
            let isContainPosition = data.data.includes(this.AdminRegForm.controls['party_sub_type_id'].value)
            if (!isContainPosition) {
              // Swal.fire("")
              // this.SubPartyTypeArray = [];
              this.AdminRegForm.patchValue({
                // party_type_id: "1",
                party_sub_type_id: null
              });
            }
          }
          this.loader.close();
          // const loginData = JSON.parse(localStorage.getItem('loginData'));
          // this.AdminRegForm.patchValue({
          //   distributor_code: loginData.distributor_code
          // });
        }
        else {
          this.loader.close();
          // console.log("data ===",data);
          this.SubPartyTypeArray = [...this.SubPartyTypeRespArray]

          this.AdminRegForm.patchValue({
            // party_type_id: "1",
            party_sub_type_id: null
          });
          if (data.data.msg != "Data not found.") {
            Swal.fire(data.data.msg)
          }
        }
      }, (err) => {
        // console.log("err ====",err);
        Swal.fire('Oops...', err.msg, 'error')
      }
    );
  }
  ngOnDestroy(): void {
    this.DataPassServic.setUserData("");
  }
}
