import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse,HttpBackend, HttpHeaders } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment, } from 'environments/environment';
import {AuthorizeService} from 'app/shared/services/MyServices/authorize.service';


@Injectable({
  providedIn: 'root'
})
export class TataServiceService {
  private httpClient: HttpClient;
  private StateURL = `${environment.TataapiUri}/api/states/`;
  private DistrictsURL = `${environment.TataapiUri}/api/districts/ `;
  private CitiesURL = `${environment.TataapiUri}/api/cities/`;
  private TalukasURL = `${environment.TataapiUri}/api/talukas/`;
  private PincodesURL = `${environment.TataapiUri}/api/pincodes/`;
  private VehicleSearchURL = `${environment.TataapiUri}/api/tataoksourcing/vehicle/search/`;


  
  readonly HttpUploadOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

   constructor(private handler: HttpBackend,private http: HttpClient,private auth:AuthorizeService) {
    this.httpClient = new HttpClient(handler);
    }

  
    VehicleSearch(data): Observable<any> {
      // const HttpUploadOptions = {
      //   headers: new HttpHeaders({ 'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getTataToken()}`,
      // })}
      return this.httpClient.post<any>(this.VehicleSearchURL,data,this.HttpUploadOptions).pipe(catchError(this.handlError));
    }
  

  BindState(Data: any): Observable<any> {
    return this.httpClient.post<any>(this.StateURL, null).pipe(catchError(this.handlError));
   // return this.httpClient.post<any>(this.StateURL,'').toPromise().thenpipe(catchError(this.handlError));
  }
  

   getBindState(Data: any): Promise<any>{
return this.httpClient.post<any>(this.StateURL,null).toPromise();
}
  
  
  BindDistrict(data: any): Observable<any> {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getTataToken()}`,
    // })}
    return this.httpClient.post(this.DistrictsURL, data,this.HttpUploadOptions).pipe(catchError(err => of(err)));
  }


  BindCity(data: any): Observable<any> {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getTataToken()}`,
    // })}
    return this.httpClient.post(this.CitiesURL, data,this.HttpUploadOptions).pipe(catchError(err => of(err)));
  }

  BindTaluka(data: any): Observable<any> {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getTataToken()}`,
    // })}
    return this.httpClient.post(this.TalukasURL, data,this.HttpUploadOptions).pipe(catchError(err => of(err)));
  }

  BindPincode(data: any): Observable<any> {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getTataToken()}`,
    // })}
    return this.httpClient.post(this.PincodesURL, data,this.HttpUploadOptions).pipe(catchError(err => of(err)));
  }
  
  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }

}
