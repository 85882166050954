import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit {
  sub = new Subscription
  public productFilter: FormGroup;
  isOpendropdown: boolean = false;
  @Input() filterFields: any;
  @Input() isRegional: any;
  @Output() filterData = new EventEmitter();
  districtArray: any;
  stateArray: any;
  public breakpoint: number;
  constructor(private commonservices: CommonService, private fb: FormBuilder,) { }
  nofOwners = [
    "First-Owner",
    "Second-Owner",
    "Third-Owner",
    "Four-Owner",
    "Fifth-Owner",
    "Six-Owner",
    "Seven-Owner",
    "Eight-Owner",
    "Nine-Owner"
  ];
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  visibilty = [
    "public",
    "private",
  ];
  ngOnInit() {
    this.onstate()
    this.productFilter = this.fb.group({
      owner: [null],
      region: [null],
      sold: [null],
      zone: [null],
      state: [null],
      district: [null],
      visiblity: [null],
      vehiclecategory: [null],
      min_odometer: [null],
      max_odometer: [null],
      vehicleType: [null],
      finance: [null],
      warranty: [null],
      emission: [null]
    })
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2;
  }
  openDropdown() {
    this.isOpendropdown = !this.isOpendropdown;
  }

  closeDropdown() {
    this.isOpendropdown = false;
  }

  onstate() {
    this.sub.add(this.commonservices.getLocations().subscribe(
      data => {
        this.stateArray = data;
      }, (err) => {
        Swal.fire(err.error.data.msg)
      }))

  }
  ondistrict(value) {
    value = this.stateArray.filter((item: any) => item.code == this.productFilter.controls['state'].value)
    value = value[0];

    let json = { "state": { "code": value.code, "state": value.state } }

    this.commonservices.getDistrctList(json).subscribe(
      data => {
        this.districtArray = data;
      }, (err) => {
        Swal.fire('Oops...', err.error.data.msg, 'error')
      }
    );
  }

  emitData() {
    this.filterData.emit(this.productFilter.value);
    this.closeDropdown();
  }

  reset() {
    this.productFilter.reset();
    this.filterData.emit("reset");
    this.closeDropdown();
  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.isOpendropdown = false
    // this.productFilter.reset();

  }
  // ngOnDestroy() {
  //   this.isOpendropdown = false
  // }
}