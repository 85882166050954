import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { RegistrationService } from '../../../shared/services/MyServices/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserRegistration } from 'app/shared/models/user-registration';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { ApproveData } from 'app/shared/models/models';
import { Page } from '../../../../../src/app/shared/models/PaginationPage';
@Component({
  selector: 'app-buyer-registration-list',
  templateUrl: './buyer-registration-list.component.html',
  styleUrls: ['./buyer-registration-list.component.scss'],
  animations: egretAnimations
})
export class BuyerRegistrationListComponent implements OnInit {
  page = new Page();
  BuyerListData: UserRegistration[];
  public items: any[];
  temp = [];
  public getItemSub: Subscription;
  readable: any;
  page_count: any;
  constructor(private router: Router,
    private RegService: RegistrationService,
    private confirmService: AppConfirmService,
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.totalElements = 0;
  }

  isPageUrlCheck: any;
  ngOnInit() {

    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'BuyerUserList') {
            this.isPageUrlCheck = "BuyerUserList";
            this.readable = entryDetail.readable
          }
        }
      }
    }

    if (this.isPageUrlCheck == "BuyerUserList") {
      this.getUserList(0)
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }
   
  }

  getUserList(Offset) {
    const ObjData: RegistrationList = {} as RegistrationList;
    ObjData.offset = Offset;

    this.getItemSub = this.RegService.GetBuyeRegistrationList(ObjData)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }
        if (data.success = true) {
          this.page_count = data.page_count;
          this.page.totalElements = data.page_count;
          this.items = [...data.data];
          this.temp = [...data.data];
          console.log(data.data);
          //this.items =this.temp = data;
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }
      });


  }

  openPopUp(data: any = {}, isNew?) {
    console.log(data);
    this.router.navigateByUrl('pages/BuyerReg');
  }

  deleteItem(row) {

  }


  Approve(data: any = {}, isNew?) {

    //alert(this.AdminRegForm.value.user_code);



    this.confirmService.confirm({ message: `Are You Sure to Active This User?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          // objData.id = data.toString();
          // alert(Id)
          let json = '{"user_code":"' + data.user_code + '", "status":"Active" }';
          this.RegService.ADminUserApprove(json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {

                Swal.fire('User Activation Successfully!');
                this.getUserList(0);
                this.router.navigateByUrl('pages/BuyerUserList');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })



  }


  Inactive(data: any = {}, isNew?) {

    //alert(this.AdminRegForm.value.user_code);



    this.confirmService.confirm({ message: `Are You Sure to Inactive This User?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          // objData.id = data.toString();
          // alert(Id)
          // let json = '{"user_code":"' + data.user_code + '"}';
          let json = '{"user_code":"' + data.user_code + '", "status":"Inactive" }';
          this.RegService.ADminUserApprove(json)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {

                Swal.fire('User Inactive Successfully!');
                this.getUserList(0);
                this.router.navigateByUrl('pages/BuyerUserList');
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })



  }

  updateFilter(event,value) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    // columns.splice(columns.length - 1);
    columns.splice(columns.length);
    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
    
    if(value){
      // console.log(value);
      this.page.totalElements = this.items.length;
    }else{
      // console.log("no value");
      this.page.totalElements = this.page_count;
    }
    

  }
  setPage(pageInfo) {
    var offset = pageInfo.offset;
    this.getUserList(offset)
  }
}

export interface RegistrationList {
  offset: number;
}