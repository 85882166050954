import { Injectable } from '@angular/core';
import {HttpClient,HttpParams, HttpErrorResponse} from '@angular/common/http'
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, of, throwError, Observable} from 'rxjs';
import {Router} from '@angular/router';
import { environment, } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BidService {

  //private BidListUrl = `${environment.TataapiUri}/api/v1/auction/list_bid_details/`;
  //New
  private BidListUrl = `${environment.TataapiUri}/api/v1/front_end/bid_listing/  `;
  private BidInventoryListUrl = `${environment.TataapiUri}/api/v1/front_end/bid_wise_inventory_list/   `;
  private BidBidderListUrl = `${environment.TataapiUri}/api/v1/front_end/inventory_wise_bid_details/`;
  private BidWinnerAPI = `${environment.TataapiUri}/api/v1/auction/bid_settlement/`;
  private BidWinnerSetttelmentListUrl = `${environment.TataapiUri}/api/v1/auction/bid_winner_listing/    `;
  private ApproveBidSettelmentUrl = `${environment.TataapiUri}/api/v1/auction/adding_bidWinner/`;


  
  constructor(private http:HttpClient,private _Router:Router) {   }

  BidList(data:any):Observable<any>
  {
     return this.http.post<any>(this.BidListUrl,data).pipe(catchError(this.handlError));
  } 
  
  //BindBidInventory 
  BindBidInventory(data:any):Observable<any>
  {
     return this.http.post<any>(this.BidInventoryListUrl,data).pipe(catchError(this.handlError));
  }  


    //BindBidWinnerSettelmemtData 
    BindBidWinner(data:any):Observable<any>
    {
       return this.http.post<any>(this.BidWinnerSetttelmentListUrl,data).pipe(catchError(this.handlError));
    }  

  //BindBiddder 
  BindBidder(data:any):Observable<any>
  {
     return this.http.post<any>(this.BidBidderListUrl,data).pipe(catchError(this.handlError));
  } 
  handlError(error:HttpErrorResponse)
  {
    return throwError(error)
  }

//BidWinner
// ApproveBidSettelment(data:any):Observable<any>
//   {
//      return this.http.post<any>(this.BidWinnerAPI,data).pipe(catchError(this.handlError));
//   }


  ApproveBidSettelment(data:any):Observable<any>
  {
     return this.http.post<any>(this.BidWinnerAPI,data).pipe(catchError(this.handlError));
  }
  


  //BidWinner
BidWinnner(data:any):Observable<any>
  {
     return this.http.post<any>(this.ApproveBidSettelmentUrl,data).pipe(catchError(this.handlError));
  }

}
