import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment, } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private GetDepositListUrl = `${environment.TataapiUri}/api/v1/auction/list_deposit_details/`;
  private ApproveDepositUrl = `${environment.TataapiUri}/api/v1/auction/approve_deposit/`;

  private GetRefundListUrl = `${environment.TataapiUri}/api/v1/auction/deposit_refund_list/`;
  private ApproveRefundUrl = `${environment.TataapiUri}/api/v1/auction/approve_refund_deposit/`;

  private GeBankListUrl = `${environment.TataapiUri}/api/v1/accounts/list_bank_details/`;
  private ApproveBankUrl = `${environment.TataapiUri}/api/v1/accounts/approve_bank_details/`;
  private viewbankdetailskUrl = `${environment.TataapiUri}/api/v1/accounts/view_bank_details/`;

  constructor(private http: HttpClient, private _Router: Router) { }

  GetDepositList(data: any): Observable<any> {
    return this.http.post<any>(this.GetDepositListUrl, data).pipe(catchError(this.handlError));
  }

  ApproveDepoist(data: any): Observable<any> {
    return this.http.post<any>(this.ApproveDepositUrl, data).pipe(catchError(this.handlError));
  }

  GetRefundList(data: any): Observable<any> {
    return this.http.post<any>(this.GetRefundListUrl, data).pipe(catchError(this.handlError));
  }

  ApproveRefund(data: any): Observable<any> {
    return this.http.post<any>(this.ApproveRefundUrl, data).pipe(catchError(this.handlError));
  }

  GetBankList(data: any): Observable<any> {
    return this.http.post<any>(this.GeBankListUrl, data).pipe(catchError(this.handlError));
  }

  ApproveBank(data: any): Observable<any> {
    return this.http.post<any>(this.ApproveBankUrl, data).pipe(catchError(this.handlError));
  }

  ViewBankDetail(data: any): Observable<any> {
    return this.http.post<any>(this.viewbankdetailskUrl, data).pipe(catchError(this.handlError));
  }





  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }

}
