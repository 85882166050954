import { Component, OnInit, DebugElement } from '@angular/core';
import { DataPassService } from 'app/shared/services/MyServices/data-pass.service';
import { Subscription } from 'rxjs';
import { BidService } from '../../../shared/services/MyServices/bid.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bid-inventory',
  templateUrl: './bid-inventory.component.html',
  styleUrls: ['./bid-inventory.component.scss'],
  animations: egretAnimations
})
export class BidInventoryComponent implements OnInit {

  constructor(private DataPassServic: DataPassService, private BidService: BidService,

    private router: Router, ) { }

  public Datas: any;
  public getItem: Subscription;
  public items: any[];
  AuctionCode: any
  BidTypeCode: any
  temp = [];
  ngOnInit() {

    this.Datas = this.DataPassServic.sGetBidInventoryata();
    //console.log(this.Datas.inventory_code);
    debugger;
    this.AuctionCode = localStorage.getItem('BidAuctionCode')
    this.BidTypeCode = localStorage.getItem('Bidtype')

    // this.getBidInventoryList(this.Datas.auction_code);


    if (this.AuctionCode == null || this.AuctionCode == '' || this.AuctionCode == undefined) {
      this.router.navigateByUrl('pages/BidInventory');
    }
    else { this.getBidInventoryList(this.AuctionCode); }

  }


  getBidInventoryList(AucCode) {
    debugger;
    //AucCode='AUCT00010';
    var json = { "auction_code": AucCode, "offset": 0 }
    // var json  = {"user_code":"CUST00001","offset":0,"auction_type": "running" }
    this.getItem = this.BidService.BindBidInventory(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
          console.log(data);
          this.items = this.temp = data.data;
          // console.log( this.items);

        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  openPopUp(data: any = {}, isNew?) {
    //this.items=data;
    //console.log(data);
    this.DataPassServic.setBidInventoryata(data);


    localStorage.setItem('BidAuctionDetailCode', data.auction_detail_code);

    if(this.BidTypeCode =='Current')
    {

      localStorage.setItem('BidType1', 'Current');
    }
    else{

      localStorage.setItem('BidType1', 'Closed');
    }

    this.router.navigateByUrl('pages/Bidder');
  }

  back() {
    if(this.BidTypeCode =='Current')
    {

      this.router.navigateByUrl('pages/BidList');
    }
    else{

      this.router.navigateByUrl('pages/ClosedBidList');
    }

    
  }

}
