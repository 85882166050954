import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { SubCategoryDetails, CotrolData, InventoryMaster, FormArrayForFile, ControlDetail, ElementData, ControlBind } from '../../../shared/models/models';
import { CustomValidators } from 'ng2-validation';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import Swal from 'sweetalert2';
import { InventoryService } from '../../../shared/services/MyServices/inventory.service';
import { AuthorizeService } from '../../../shared/services/MyServices/authorize.service';
import { CommonService } from '../../../shared/services/MyServices/common.service';
import { TataServiceService } from '../../../TataService/tata-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FileUploader } from 'ng2-file-upload';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../shared/directives/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import {VehicleData} from 'app/shared/models/models';

@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss'],
  animations: egretAnimations,
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class AddInventoryComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({ url: 'https://evening-anchorage-315.herokuapp.com/api/' });
  public hasBaseDropZoneOver: boolean = false;
  console = console;

  public showForm: boolean = false;

  BasicFormGroup: FormGroup;
  TechnicalFormGroup: FormGroup;
  OtherFormGroup: FormGroup;

  InventoryForm: FormGroup;
  InventoryUploads: FormArray;
  imageSrc: string;
  //public AllImageFile: any[];
  AllImageFile = [];
  AllVechileImages: any;
  DocumentFile: any;
  testFile: any;
  CoverImageFile: any;
  // CoverImageSrcUrls: any;

  cat_id: any;
  sub_cat_id: any;


  imageSrcUrls = [];
  control_detail_id = [];
  BasicDetailsArray = [];
  TechnicalDetailsArray = [];
  OtherArray = [];


  LhsImageArray = [];
  RhsImageArray = [];
  InsideImageArray = [];
  CoverImageArray = [];
  FrontImageArray = [];
  BackImageArray = [];
  AllTyreImageArray = [];
  dataFile = [];
  ControlMenuArray: any[];
  InventoryArray: any[];
  categoryArray: any[];
  SubcategoryArray: any[];
  CategoryId: any;
  SubCategoryId: any;
  ElementArray: any[];

  StateArray: any[];
  DistictArray: any[];
  CityArray: any[];
  TalukaArray: any[];
  PincodeArray: any[];

  stateCode: string;
  stateName: string;
  districtName: string;
  cityName: string;
  talukaName: string;


  constructor(private fb: FormBuilder,
    private router: Router,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private commonService: CommonService,
    private AuthService: AuthorizeService,
    private imageCompress: NgxImageCompressService,
    private FileUpService: FileUploadService,
    private TataService: TataServiceService,
    private InvenService: InventoryService) {


  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    alert(e);
  }



  ngOnInit() {
    this.SearchVehicle();
    this.BasicDetailsArray = [];
    this.TechnicalDetailsArray = [];
    this.OtherArray = [];
    this.urls = [];
    // this.CoverImageSrcUrls = [];
    // this.InventoryForm = new FormGroup({
    //   InventoryType: new FormControl('', [Validators.required]),
    //   category: new FormControl('', [Validators.required]),
    //   Subcategory: new FormControl('', [Validators.required]),
    // })

    this.InventoryForm = this.fb.group({
      InventoryType: ['', Validators.required],
      category: ['', Validators.required],
      Subcategory: ['', Validators.required],
      //InventoryUploadData: this.fb.array([this.bindValueArray('', '')])
      InventoryUploadData: this.fb.array([])

    })

    this.BindInventory();
  }

  BindInventory() {
    this.commonService.BindInventory().subscribe(
      data => {
        if (data.success == true) {
          this.InventoryArray = data.data;
        }
        else {
          Swal.fire('Oops...', data.msg, 'error')
        }
      }, (err) => {
        Swal.fire('Oops...', err.msg, 'error')
      }
    );
  }



  onSelectInventory(value) {
    this.cat_id = "";
    const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    dataList.cat_id = value.cat_id;
    this.cat_id = value.cat_id;
    this.commonService.BindCategory(dataList).subscribe(
      data => {

        if (data.success == true) {
          this.categoryArray = data.data;
        }
        else {
          Swal.fire(data.msg)
        }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      }
    );
  }

  FormCategory(id): SubCategoryDetails {
    const data: any = {} as any;
    data.cat_id = id;
    return data
  }

  onSelectcategory(cateval) {
    this.sub_cat_id = "";
    const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    dataList.subcat_id = cateval.sub_cat_id;
    this.sub_cat_id = cateval.sub_cat_id;
    this.commonService.BindSubCategory(dataList).subscribe(
      data => {

        if (data.success == true) {
          this.SubcategoryArray = data.data;
        }
        else {
          Swal.fire(data.msg)
        }
      }, (err) => {

        Swal.fire(err.error.data.msg)

      });
  }

  onSelectSubcategory(Subcateval) {
   // debugger;
    this.ControlMenuArray = [];
    this.BasicDetailsArray = [];
    this.TechnicalDetailsArray = [];
    this.OtherArray = [];

    const ControlList: ControlBind = {} as ControlBind;
    ControlList.catId = this.cat_id;
    ControlList.subcat_id = this.sub_cat_id;
    ControlList.subcat_detail_id = Subcateval.sub_cat_detail_id;
    // this.ControlMenuArray = [];
    //  let json = '{"catId":' + this.cat_id + ',"subcat_id":' +   this.sub_cat_id + '}';
    //const dataList = JSON.parse(json);
    this.commonService.BindControls(ControlList).subscribe(
      data => {
     //   debugger;
        this.ControlMenuArray = [];
        if (data.success == true) {

          this.ControlMenuArray = data.data;
          // this.BasicDetailsArray= this.ControlMenuArray.find(x => x.group = 'Basic Details');
          // this.TechnicalDetailsArray= this.ControlMenuArray.find(x => x.group = 'Technical Details');
          // this.OtherArray= this.ControlMenuArray.find(x => x.group = 'Other');


          this.showForm = true;
          for (let i = 0; i < this.ControlMenuArray.length; i++) {
            let control_name = this.ControlMenuArray[i]['control_name'];
            let mapping_id = this.ControlMenuArray[i]['mapping_id'];

            let ControlType = this.ControlMenuArray[i]['control_type'];
            let element_id = this.ControlMenuArray[i]['element_id'];
            let element_name = this.ControlMenuArray[i]['element_name'];


            let group = this.ControlMenuArray[i]['group'];

            if (group == 'Basic Details') {
              this.BasicDetailsArray.push(this.ControlMenuArray[i]);
            }

            if (group == 'Technical Details') {
              this.TechnicalDetailsArray.push(this.ControlMenuArray[i]);
            }

            if (group == 'Other') {
              this.OtherArray.push(this.ControlMenuArray[i]);
            }

            //Bind State 
            if (control_name == 'DrpState') {
              this.TataService.BindState('').pipe(first()).subscribe(res => {
                if (res instanceof HttpErrorResponse) {
                  return;
                }
                if (res.length > 0) {
                  this.StateArray = res;
                  this.DistictArray = [];
                  this.CityArray = [];
                  this.TalukaArray = [];
                  this.PincodeArray = [];
                }
                else {
                  const errorMessage = res;
                  console.log(errorMessage);
                  return;
                }
              },
                error => {
                  console.log(error);
                })
            }
            //Bind State 
            if (this.ControlMenuArray[i]['ismandatory'] == true) {
              if (ControlType == 'GeoDropdown' || ControlType == 'Dropdown' || ControlType == 'Checkbox' || ControlType == 'RadioButton' || ControlType == 'FileUpload') {
                this.InventoryForm.addControl(mapping_id, new FormControl('', Validators.required));
              }

              else {
                this.InventoryForm.addControl(mapping_id, new FormControl('', Validators.required));
              }
            }
            else {
              this.InventoryForm.addControl(mapping_id, new FormControl());
            }


            // mapping_id=null;
          }

        }
        else {
          this.showForm = false;
          Swal.fire(data.msg)
        }
      }, (err) => {
        this.ControlMenuArray = [];
        this.showForm = false;
        Swal.fire(err.error.data.msg)

      }
    );
  }


  onSelectState(value) {
    this.stateCode = value.code;
    this.stateName = value.name;
    this.InventoryForm.value.state = value.code
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"}}';
    this.TataService.BindDistrict(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.DistictArray = res;
        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }



  onSelectDistrict(value) {
    this.districtName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '"}';
    this.TataService.BindCity(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.CityArray = res;
        this.TalukaArray = [];
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  onSelectCity(value) {
    this.cityName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '"}';
    this.TataService.BindTaluka(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.TalukaArray = res;
        this.PincodeArray = [];
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }

  onSelectTaluka(value) {
    this.talukaName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '","city":"' + this.cityName + '","taluka":"' + this.talukaName + '"}';
    this.TataService.BindPincode(json).pipe(first()).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.pincodes.length > 0) {
        this.PincodeArray = res.pincodes;
      }
      else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      error => {
        console.log(error);
      });
  }


  onItemDeleted(index: number) {
    // this.imageSrcUrls.splice(index, 1);
    this.urls.splice(index, 1);
  }


  btnsubmitForm() {
    this.console.log(this.ControlMenuArray);
    //     var Check = false;
    // if (this.AllImageFile.length > 0) {
    //       var fileAmount = this.AllImageFile.length;
    //       for (let i = 0; i < fileAmount; i++) {
    //         const formData = new FormData();
    //         formData.append('user_code', "CUST00001");
    //         formData.append('inventory_code', "INVE00001");
    //         formData.append('contract_type', 'inventory');
    //         formData.append('images_or_documents', this.AllImageFile[i]);
    //         formData.append('cover_image', this.DocumentFile);
    //         Check = true;
    //         if (Check) {

    //           this.FileUpService.uploadImageDocument(formData).subscribe(data => {
    //             debugger;
    //             console.log('Upload Image document ' + data);
    //           });
    //         }
    //         else {


    //         }
    //       }
    //     }



    if (this.InventoryForm.invalid) {
      Swal.fire("Please fill in the required fields", "");
      return;
    }


    var i = 0;
    this.control_detail_id = [];
    for (var key in this.InventoryForm.value) {

      var ids = parseInt(key);
      let elenames = this.ControlMenuArray.find(x => x.mapping_id === ids);

      if (key == "category") {
      }
      else if (key == "Subcategory") {
      }
      else if (key == "InventoryType") {
      }
      else if (key == "InventoryUploadData") {
      }
      else if (elenames.element_name == "Evaluation Report") {
      }
      else if (elenames.element_name == "Vehicle Image") {
      }
      else if (elenames.element_name == "Cover Image") {
      }
      else {
        // var elename="";
        var id = parseInt(key);
        //var elename = this.ControlMenuArray[i].element_name;
        let elename = this.ControlMenuArray.find(x => x.mapping_id === id);
        var value = this.InventoryForm.value[key];
        if (elename.element_name == "State") {
          //  value = value.name;
          value = "";
        } else {

        }

        this.control_detail_id.push(this.formData(key, value, elename.element_name));
      }
      value = "";
      i++;
    }
    if (this.control_detail_id.length > 0) {
      const InventoryMasterData: InventoryMaster = {} as InventoryMaster;
      var PartyId = 1;//parseInt(this.commonService.getDealerCode());
      InventoryMasterData.user_id = parseInt(this.commonService.getUserCode());
      InventoryMasterData.cat_id = parseInt(this.InventoryForm.value.InventoryType.cat_id);
      InventoryMasterData.sub_cat_id = parseInt(this.InventoryForm.value.category.sub_cat_id);
      InventoryMasterData.sub_cat_detail_id = parseInt(this.InventoryForm.value.Subcategory.sub_cat_detail_id);

      InventoryMasterData.control_details = this.control_detail_id

      //  console.log('Form Submitted with value: ', this.InventoryForm.value, this.InventoryForm.valid);
      console.log('MapIdArray: ', InventoryMasterData);
      let json = InventoryMasterData;
      this.InvenService.AddInventory(InventoryMasterData).pipe(first()).subscribe(res => {
        if (res instanceof HttpErrorResponse) {
          return;
        }

        if (res.success = true) {
        //  debugger;
          var Check = false;

          const formData = new FormData();
          formData.append('user_code', this.commonService.getUserCode1());
          formData.append('inventory_code', res.data.inventory_code);
          formData.append('contract_type', 'inventory');
          formData.append('evaluation_criteria', this.DocumentFile);
          for (let i of this.InventoryForm.get('InventoryUploadData').value) {
            formData.append(i.ImagePositionType, i.FileName);
          }

          Check = true
          if (Check) {
            this.FileUpService.uploadInventory(formData).subscribe(data => {
            //  debugger;
              console.log('Upload Image document ' + data);
            });
          }
          else {
          }
          this.InventoryForm.reset();
          Swal.fire('Successfully saved!', 'success');
          this.router.navigate(['pages/InventoryList'])


        }
        else {
          Swal.fire(res.data.msg, 'Error')
        }

      },
        error => {
          Swal.fire(error.error.data.msg, "Error");
        });
    }
  }

  formData(key, value, elementid): ControlDetail {
    const ControlDetailByMapId: ControlDetail = {} as ControlDetail;
    ControlDetailByMapId.cat_mapid = parseInt(key);
    ControlDetailByMapId.control_detail_id = 1;
    ControlDetailByMapId.control_detail_value = value;
    ControlDetailByMapId.element_detail_value = elementid
    return ControlDetailByMapId;
  }

  formElementData(elementid, elementvalue): ElementData {
    const ElementArrayData: ElementData = {} as ElementData;
    ElementArrayData.element_id = elementid;
    ElementArrayData.element_name = elementvalue;
    return ElementArrayData;
  }

  onClear() {
    this.showForm = false;
    this.BasicDetailsArray = [];
    this.TechnicalDetailsArray = [];
    this.OtherArray = [];
    this.ControlMenuArray = [];
    this.imageSrcUrls = [];
    this.urls = [];
    // this.CoverImageSrcUrls = [];
    this.InventoryForm.reset();
  }
  urls = new Array<string>();

  SelectImageFiles(event) {
 //   debugger;
    this.urls = [];
    let files = event.target.files;
    //this.AllVechileImages = files;
    if (files) {
      for (let file of files) {
        this.AllImageFile.push(file);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          //this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
      if (this.urls.length = files.length) {
        this.BindControlArray(files);
      }
    }


  }

  // // SelectCoverImage(event) {
  // //   if (event.target.files && event.target.files[0]) {
  // //     var Extension = event.target.files[0].name.substring(
  // //       event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
  // //   //  if (Extension == 'JPEG' || Extension == 'jpg' || Extension == 'png') {
  // //       const reader = new FileReader();
  // //       const file = event.target.files[0];
  // //       this.CoverImageFile = file;
  // //       if (file.size < 5000000) {
  // //         reader.readAsDataURL(event.target.files[0]);
  // //         reader.onload = (event) => {
  // //           let target: any = event.target;
  // //           this.imageCompress.compressFile(target.result, -2, 50, 50).then(
  // //             result => {
  // //               var imageBase64 = result;
  // //               console.log(result);
  // //               var binary = this.base64toBlob(result, 'image/jpeg');
  // //               var blob_ = new Blob([binary], { type: 'image/jpeg' });
  // //               var file = new File([blob_], 'test.jpg', { type: 'image/jpeg' });
  // //               const reader = new FileReader();
  // //               const filecompress = file;
  // //               reader.readAsDataURL(filecompress);

  // //               reader.onload = (event) => {
  // //                 let target: any = event.target;
  // //                // this.CoverImageSrcUrls = imageBase64;

  // //               }
  // //             }
  // //           );

  // //         }

  // //       }
  // //       else {
  // //         Swal.fire('Oops...', 'Upload only 5 MB size files!', 'error')
  // //       }

  // //     // }
  // //     // else {

  // //     //   Swal.fire('Oops...', 'Photo only allows file types of  PNG, JPG and JPEG.', 'error')
  // //     // }

  // //   }
  // // }

  SelectDocumentFiles(event) {
    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
      const reader = new FileReader();
      const file = event.target.files[0];
      this.DocumentFile = file;
      console.log(this.DocumentFile);
      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
          // this.DocumentFile = target.result;
        }
      }
      else {
        Swal.fire('Oops...', 'Upload only 5 MB size files!', 'error')
      }

    }

    //  debugger;
    //  var Check = false;


    // console.log(this.InventoryForm.get('InventoryUploadData').value);
    // var data= this.form_data_for_image_upload(this.InventoryForm.get('InventoryUploadData').value)
    // // // const formData = new FormData();
    // // // formData.append('user_code', 'CUST00001');
    // // // formData.append('inventory_code', 'INVE00010');
    // // // formData.append('contract_type', 'inventory');
    // // // // formData.append('contract_type', 'inventory');
    // // // for(let i of this.InventoryForm.get('InventoryUploadData').value)
    // // // {
    // // //   formData.append(i.ImagePositionType, i.FileName);
    // // // }
    // formData.append('lhs', this.AllImageFile[i]);
    // formData.append('front', '');
    // formData.append('inside_cabin', '');
    // formData.append('all_tyre', '');
    // formData.append('back', '');

    // if (i == 0) {
    //   if (this.DocumentFile != undefined) {
    //     formData.append('evaluation_criteria', this.DocumentFile);
    //  //  formData.append('evaluation_criteria', '');
    //   }
    //   formData.append('cover_image', this.AllImageFile[i]);
    //   // if (this.CoverImageFile != undefined) {
    //   //   formData.append('cover_image', this.CoverImageFile);
    //   // }

    // }
    // else {
    //   formData.append('evaluation_criteria', '');
    //   formData.append('cover_image', '');
    // }

    // // // Check = true
    // // // if (Check) {
    // // //   this.FileUpService.uploadInventory(formData).subscribe(data => {
    // // //     debugger;
    // // //     console.log('Upload Image document ' + data);
    // // //   });
    // // // }
    // // // else {
    // // // }


  }

  form_data_for_image_upload(file_list) {
  //  debugger;
    let temp = ''
    let first = false
    let array_file_temp = []
    let second = false
    let file_data_final = []
    let i = 0;
    for (let file of file_list) {
      i = i + 1
      if (!first) {
        temp = file.ImagePositionType
        first = true
      }
      if (temp != file.ImagePositionType) {
        const dataList: FormArrayForFile = {} as FormArrayForFile;
        dataList.stage = temp
        dataList.formData = array_file_temp
        file_data_final.push(dataList)
        array_file_temp = []
      }
      temp = file.ImagePositionType
      if (temp == file.ImagePositionType) {
        array_file_temp.push(file.FileName)
      }
      if (i == file_list.length) {
        const dataList: FormArrayForFile = {} as FormArrayForFile;
        dataList.stage = temp
        dataList.formData = array_file_temp
        file_data_final.push(dataList)
      }

    }
    return file_data_final
  }


  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
      // if (Extension == 'JPEG' || Extension == 'jpg' || Extension == 'png') {
      const reader = new FileReader();
      const file = event.target.files[0];
      //this.CompressImage = file;
      //this.imageSrcUrls.push(file);
      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
          this.imageCompress.compressFile(target.result, -2, 50, 50).then(
            result => {
              var imageBase64 = result;
              console.log(result);
              var binary = this.base64toBlob(result, 'image/jpeg');
              var blob_ = new Blob([binary], { type: 'image/jpeg' });
              var file = new File([blob_], 'test.jpg', { type: 'image/jpeg' });
              const reader = new FileReader();
              const filecompress = file;
              reader.readAsDataURL(filecompress);

              reader.onload = (event) => {
                let target: any = event.target;
                this.imageSrc = imageBase64;
                //this.imageSrcUrls.push(imageBase64);
                //  this.firstFormGroup.get('imageUrl').setValue(imageBase64);
              }
              //  this.firstFormGroup.get('image').setValue(filecompress);
              // this.firstFormGroup.get('updatedImageSatus').setValue('1');
            }
          );

        }

      }
      else {
        Swal.fire('Oops...', 'Upload only 5 MB size files!', 'error')
      }

      // }
      // else {

      //   Swal.fire('Oops...', 'Photo only allows file types of  PNG, JPG and JPEG.', 'error')
      // }

    }

  }
  onSearchChange(searchValue: string): void   
  {
    for (var key in this.InventoryForm.value) {

      var ids = parseInt(key);
      let elenames = this.ControlMenuArray.find(x => x.mapping_id === ids);

      if (key == "category") {
      }
      else if (key == "Subcategory") {
      }
      else if (key == "InventoryType") {
      }
      else if (key == "InventoryUploadData") {
      }
      else if (elenames.element_name == "Evaluation Report") {
      }
      else if (elenames.element_name == "Vehicle Image") {
      }
      else if (elenames.element_name == "Cover Image") {
      }
      else {
        if (elenames.element_name == "Inventory Title") {
        this.InventoryForm.controls.ids.setValue('foo')
        }
      }
    }
alert(searchValue);
  }
  SearchVehicle() {
    var json = {"vehicle_type":"TV","chassis_number":"426021MVZ753622","registration_number": "","dev_id": "89761","app_version": "1.0","app_name": "com.tatamotors.tataoksourcing"}
    this.TataService.VehicleSearch(json).subscribe(
      data => {

        if (data.vehicle_info) {
          console.log(data.vehicle_info);
         data as  VehicleData;
        
        }
        else {
         // Swal.fire('Oops...', 'incorrect username or password!', 'error')
        }
      }, (err) => {

      }
    );
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


  BindControlArray(data) {
    this.InventoryForm['InventoryUploadData'] = []
    for (let file of data) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        //this.urls.push(e.target.result);
        this.getmultipleControl.push(this.bindValueArray(file, e.target.result))
     //   debugger;
        console.log('hello')
        console.log(this.getmultipleControl)
        console.log(this.InventoryForm.get('InventoryUploadData'))
      }
    }

  }

  get getmultipleControl() {
    return <FormArray>this.InventoryForm.get('InventoryUploadData');
  }

  BindValue() {
    this.fb.group({
      InventoryUploadData: this.fb.array([this.bindValueArray('', '')])
    })

  }

  bindValueArray(FileName, Base64) {
    return this.fb.group(
      {
        FileImageSrc: [Base64],
        FileName: [FileName],
        ImagePositionType: [''],
      }
    )
  }

}
