import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
//import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { AuctionMaster, ApproveData } from '../../../shared/models/models';
import { AuctionService } from '../../../shared/services/MyServices/auction.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {DataPassService} from '../../../shared/services/MyServices/data-pass.service';
import {Page} from '../../../../../src/app/shared/models/PaginationPage';
import { ListInput } from '../../../../../src/app/shared/models/list-input';

@Component({
  selector: 'app-auction-list',
  templateUrl: './auction-list.component.html',
  styleUrls: ['./auction-list.component.scss'],
  animations: egretAnimations
})
export class AuctionListComponent implements OnInit {
  page = new Page();


  AuctionListData: AuctionMaster[];
  public items: any[];
  temp = [];
  public datas: any;
  public getItemSub: Subscription;
  constructor(private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private AuctionService: AuctionService,
    private Passdata: DataPassService,
  ) {

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.totalElements=0;

   }

  ngOnInit() {

    const AuctionMasterData: AuctionMaster = {} as AuctionMaster;
    //
    //AuctionMasterData.user_id = 1;
    AuctionMasterData.offset = 0;
   
    this.getItems(AuctionMasterData)
   //this.loader.open();
   
  }
  ngOnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe()
    }
  }
  getItems(Imput) {

    this.getItemSub = this.AuctionService.AuctionList(Imput)
      .subscribe(data => {
    //    this.loader.close();
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success = true) {
          this.page.totalElements=data.page_count;
          
          this.items = this.temp = data.data as AuctionMaster[];
        //  console.log('this.items');
          //console.log(data);
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      }, error => {
        this.loader.close();
        Swal.fire(error.error.data.msg);
        console.log(error);
      });

    // this.getItemSub = this.crudService.getItems()
    //   .subscribe(data => {
    //     this.items = data;
    //   })
  }




  checkcolor(Color) {
    // console.log(Color);
     if (Color == "APPROVED") {
       return 'green';
     }
     else {
       return 'red';
     }
 }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
  const AuctionMasterData: AuctionMaster = {} as AuctionMaster;
  AuctionMasterData.offset = pageInfo.offset;
    this.getItems(AuctionMasterData)
  }


  Edit(data: any = {}, isNew?) {
  // console.log(data);



  //  this.Passdata.setOption(data); 
   this.serviceData(data.auction_code);
   localStorage.setItem('AuctionCode',data.auction_code);
 
  }

  serviceData(auction_code)
  {
    

    let json = '{"auction_code":"'+ auction_code +'"}';
       this.AuctionService.ViewAuctionByCode(json)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }

        if (data.success) {
        //  alert(auction_code);
          this.datas=data.data;
          //console.log('this.datas');
          //console.log(this.datas);
          this.Passdata.setOption(this.datas); 
          this.router.navigateByUrl('pages/Auction');
         
        }
        else {
          Swal.fire(data.data.msg, 'Error')
        }

      }, error => {
        Swal.fire(error.error.data.msg);
        console.log(error);
      });
  }

  deleteItem(row) { }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  ApproveAction(data: any = {}, isNew?) {
    this.confirmService.confirm({ message: `Approve ${data.auction_title}?` })
      .subscribe(res => {
        if (res) {
          const objData: ApproveData = {} as ApproveData;
          objData.id = data.auction_id.toString();
          // alert(Id)
          this.AuctionService.ApproveAction(objData)
            .subscribe(data => {
              if (data instanceof HttpErrorResponse) {
                return;
              }
              if (data.success = true) {
                Swal.fire('Auction Approved Successfully!');
                const AuctionMasterData: AuctionMaster = {} as AuctionMaster;
               // AuctionMasterData.user_id = 1;
                AuctionMasterData.offset = 0;
                this.getItems(AuctionMasterData)
              }
              else {
                Swal.fire(data.data.msg, 'Error')
              }
            });
        }
      })
  }
}

